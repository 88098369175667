import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { parseJSON } from '@icp/utils';
import { AIAgentFloatButton, AIAgent } from '@icp/components';
import { withSuspense } from '@icp/form-renderer';
import markdownCodeComponents from './markdownCodeComponents';
import { handleSave } from './markdownCodeComponents/utils';
import { useCurrentPageForm } from './CurrentPageFormStoreProvider';
import restApi from './services/restApi';

const ACTION_PROMPTS = {
  OPEN_FORM: '// action:open_form',
};

const greetingTaskCache = { promise: null, result: null };
function loadGreetings() {
  if (greetingTaskCache.result) return greetingTaskCache.result;
  if (greetingTaskCache.promise) throw greetingTaskCache.promise;
  greetingTaskCache.promise = restApi
    .get('/flow/api/flow-rest/general-ai-agent/greeting', {
      skipResponseInterceptors: true,
    })
    .then((res) => res.results || [])
    .catch(() => [])
    .then((greetings) => {
      greetingTaskCache.result = greetings;
    });
  throw greetingTaskCache.promise;
}

function GeneralAIAgent({ userProfile, pbcToken: systemAgentPbcToken }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const buttonRef = useRef();
  const aiAgentRef = useRef();
  const aiAgentDelegates = useRef({});
  const currentPageForm = useCurrentPageForm();

  const greetingsRef = useRef();
  greetingsRef.current = loadGreetings();

  useEffect(() => {
    if (!currentPageForm) return () => {};

    const listener = () => {
      const {
        context: { pbcToken, formEntityToken },
        formData: { values: formEntityData },
      } = currentPageForm.store.getState();

      const newUserContext =
        pbcToken && formEntityToken
          ? {
              pbcToken,
              formEntityToken,
              formEntityData,
            }
          : null;
      aiAgentDelegates.current.getUserContext = () => newUserContext;
      sendUserContextMessage();
    };

    currentPageForm.formApi.on('ready', listener);
    if (currentPageForm.formApi.stage === 'ready') {
      listener();
    }
    return () => {
      currentPageForm.formApi.off('ready', listener);
    };
  }, [currentPageForm]);

  const onCustomSSEMessage = (message) => {
    if (/^FORM_AUTOFILL$/i.test(message.event)) {
      const data = parseJSON(parseJSON(parseJSON(message.data)?.data)?.form_data);
      if (data) {
        currentPageForm?.formApi?.setData(data);
      }
    }
  };

  const promptAnswerActions = (msgContent) =>
    [
      pathname === '/personal-dashboard' &&
        /```(icp-table|icp-chart)/.test(msgContent || '') && {
          label: t('save', { ns: 'icp-common' }),
          handlerKey: 'onSave',
        },
    ].filter(Boolean);

  const renderPromptText = (text) => {
    if (text.startsWith(ACTION_PROMPTS.OPEN_FORM)) {
      const userContext = JSON.parse(text.replace(ACTION_PROMPTS.OPEN_FORM, ''));
      return `User is opening form: ${userContext.formEntityToken}`;
    }
    return text;
  };

  const sendUserContextMessage = () => {
    if (!buttonRef.current?.open) return;
    const greetingMessage = greetingsRef.current?.find(
      (x) => x.match && window.location.href.match(new RegExp(x.match, 'i')),
    )?.message;
    if (greetingMessage) {
      aiAgentRef.current?.appendMessages({
        id: uuidv4(),
        type: 'answer',
        isSelf: false,
        content: { text: greetingMessage },
      });
      return;
    }
    const userContext = aiAgentDelegates.current?.getUserContext?.();
    if (!userContext?.pbcToken || !userContext?.formEntityToken) return;
    const message = `${ACTION_PROMPTS.OPEN_FORM}\n${JSON.stringify(userContext)}`;
    aiAgentRef.current?.sendMessage(message, [], userContext);
  };

  return (
    <AIAgentFloatButton ref={buttonRef}>
      <AIAgent
        ref={aiAgentRef}
        userProfile={userProfile}
        uiConfiguration={{
          newChatSuppressConfirm: true,
          headerTabs: ['session'],
          inputToolbar: {
            hideModelPicker: true,
          },
        }}
        chatConfiguration={{
          useAgents: true,
          apiUrls: {
            getActionList: `/form/api/v2/form-entity-data/${systemAgentPbcToken}/system-agent-form/{agent-id}/grid-list/actions`,
          },
        }}
        markdownCodeComponents={markdownCodeComponents}
        eventHandlers={{
          onSave: (msg, action, options) => handleSave(msg, currentPageForm?.formApi, options),
          onCustomSSEMessage,
          renderPromptText,
        }}
        promptAnswerActions={promptAnswerActions}
        onAgentChanged={(agent) => {
          if (agent) {
            sendUserContextMessage();
          }
        }}
      />
    </AIAgentFloatButton>
  );
}

GeneralAIAgent.propTypes = {
  userProfile: PropTypes.shape({}),
  pbcToken: PropTypes.string,
};

export default withSuspense({ fallback: null })(GeneralAIAgent);
