import { yupToFormErrors } from 'formik';
import { message } from '@icp/settings';
import { makeYupSchema } from '@icp/form-renderer-core';
import { isInputField } from '@icp/form-schema';
import { getTFunc } from '@icp/i18n';
import { forEachColumnDefs } from '../TableElement/utils';
import COLUMN_TYPES from '../TableElement/columnTypes';
import { cellEditorMapping } from '../TableElement/cellEditors';

function getEditorComponentName(cellEditor) {
  switch (cellEditor) {
    case 'TextCellEditor':
    case 'ParentCellEditor':
      return 'Input';
    case 'DateCellEditor':
      return 'DatePicker';
    case 'NumberCellEditor':
      return 'NumberPicker';
    case 'ACLCellEditor':
      return 'ACL';
    case 'SelectCellEditor':
      return 'Select';
    case 'UploadCellEditor':
      return 'Upload';
    case 'TextareaCellEditor':
      return 'Textarea';
    default:
      return '';
  }
}

function hasValidation(colDef) {
  return (
    colDef.editable === true &&
    colDef.colId &&
    colDef.cellEditor &&
    Object.keys(colDef.cellEditorParams?.validation || {}).length
  );
}

export function makeTableYupSchema(columnDefs, defaultColDef, disabled, readonly) {
  if (!columnDefs || columnDefs.length === 0 || disabled || readonly) return null;

  const colDefsWithValidation = [];

  forEachColumnDefs(columnDefs, (colDef) => {
    if (hasValidation(colDef)) {
      colDefsWithValidation.push(colDef);
    }
  });

  const validations = colDefsWithValidation
    .map((colDef) => {
      const { colId, headerName, cellEditor, cellEditorParams } = colDef;
      const { validation, componentProps } = cellEditorParams;

      const component = getEditorComponentName(cellEditor);
      const editable = colDef.editable ?? defaultColDef?.editable;

      if (editable !== true || !isInputField({ component })) {
        return undefined;
      }

      // compose validation for yupUtil
      return {
        id: colId,
        title: headerName,
        component,
        // Select need valueType
        componentProps: component === 'Select' ? componentProps : undefined,
        validation,
        // table column only has editable prop, use it as readonly for yupUtil
        readonly: !editable,
        disabled: undefined,
        hidden: undefined,
      };
    })
    .filter(Boolean);

  return makeYupSchema(validations);
}

export function runCellValidate({ yupSchema, colDef, data, value }) {
  if (!hasValidation(colDef)) {
    return true;
  }

  let passed = true;

  try {
    yupSchema.validateSyncAt(colDef.colId, { ...data, [colDef.colId]: value });
  } catch (e) {
    if (e.name === 'ValidationError') {
      const error = yupToFormErrors(e);
      message.error({ content: error[colDef.colId] });
    }
    passed = false;
    console.error(e);
  }

  return passed;
}

function runRowValidate({ yupSchema, data }) {
  let passed = true;

  try {
    yupSchema.validateSync(data);
  } catch (e) {
    if (e.name === 'ValidationError') {
      const errors = yupToFormErrors(e);
      message.error({ content: Object.values(errors)[0] });
    }
    passed = false;
    console.error(e);
  }

  return passed;
}

export function runColumnValidate({ colDef, data }) {
  if (!hasValidation(colDef)) {
    return true;
  }

  let passed = true;

  const { unique } = colDef.cellEditorParams.validation;

  if (unique) {
    const presentedSet = new Set();
    for (const row of data) {
      const value = row[colDef.colId];
      const getUniqueKey =
        cellEditorMapping[
          colDef.cellEditor || COLUMN_TYPES[colDef.type]?.cellEditor || 'TextCellEditor'
        ]?.getUniqueKey || ((params) => params.value);

      const key = `${getUniqueKey({ colDef, value })}`;
      if (presentedSet.has(key)) {
        passed = false;
        const t = getTFunc('icp-form-renderer');
        message.error({ content: t(`validation.dataIsDuplicated`) });
        break;
      }
      presentedSet.add(key);
    }
  }

  return passed;
}

export function isDataValid({ yupSchema, columnDefs, rowData }) {
  if (!yupSchema) {
    return true;
  }
  const colDefsWithValidation = [];

  forEachColumnDefs(columnDefs, (colDef) => {
    if (hasValidation(colDef)) {
      colDefsWithValidation.push(colDef);
    }
  });

  for (const row of rowData) {
    const passed = runRowValidate({ yupSchema, data: row });
    if (!passed) {
      return false;
    }
  }

  for (const colDef of colDefsWithValidation) {
    const passed = runColumnValidate({ colDef, data: rowData });
    if (!passed) {
      return false;
    }
  }

  return true;
}
