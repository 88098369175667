import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { get, isEqual, set } from 'lodash-es';
import { restApi } from '@icp/settings';
import { immutableMove, saveAsJson } from '@icp/utils';
import {
  schemaFieldToDataField,
  findSchemaFieldByToken,
  forEachInputFieldInSchema,
  isInputField,
} from '@icp/form-schema';
import { camelCase } from 'case-anything';
import { selectFormEntityId, selectPbc, selectProject } from './contextSlice';
import {
  Converter,
  makeNewFormEntity,
  makeNewFieldFromSnippet,
  makeFieldFromToken,
  syncSchemaAllInputFields,
  insertNewFieldToFields,
  appendFieldOperation,
  insertFieldOperation,
  deleteFieldOperation,
  moveFieldOperation,
  updateFieldOperation,
  filterFieldsNotInAnyLayouts,
  // isCrossLayoutProperty,
  makeNewFieldFromBusinessField,
  makeNewField,
  makeNewTableColumn,
  embedFieldInOperation,
  syncOneInputAllPropertiesToOtherLayouts,
  makeNewStep,
  moveBeforeOrAfterOperation,
  makeNewCollapseStep,
  makeNewTab,
  getDataSourceScope,
  legacySupportEntity,
  pasteOperation,
  removeSelfOperation,
  syncOneInputCrossProperties,
  getReferenceReciprocal,
  handleReferenceReciprocal,
  colIndexToColId,
  toGanttColumnDefs,
  toTableColumnDefs,
  moveOrderOperation,
} from '../utils';
import {
  FORM_DESIGNER_WORKSPACE_MODE,
  DATA_SECURITY_SCOPE_SELF,
  DATA_SECURITY_OPERATOR_AND,
  DATA_SECURITY_OPERATOR_EQUALS,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_TAB,
  FORM_DESIGNER_DEVICE_MODE,
  SPLIT_LAYOUT_SETTING_KEY,
} from '../../constant';

const UNDO_STACK_MAX = 30;

/// --- export selectors ---

const selectThis = (state) => state.formEntity;

export const selectAIGenerating = createSelector(selectThis, (state) => state.isAIGenerating);

export const selectPbcId = (state) => state.context.pbcId || state.formEntity.formEntity.pbcId;

export const selectFormEntity = createSelector(selectThis, (state) => state.formEntity);

export const selectOriginalFormEntity = createSelector(
  selectThis,
  (state) => state.originalFormEntity,
);

export const selectSubFormEntitiesDraft = createSelector(
  selectThis,
  (state) => state.subFormEntitiesDraft,
);

export const selectIsFormEntityDirty = createSelector(
  selectFormEntity,
  selectOriginalFormEntity,
  (current, original) => !isEqual(current, original),
);

export const selectFormEntityDomain = createSelector(
  selectFormEntity,
  (formEntity) => formEntity?.domain,
);
export const selectFormEntityModule = createSelector(
  selectFormEntity,
  (formEntity) => formEntity?.module,
);

export const selectFormEntityIsAutoLock = createSelector(
  selectFormEntity,
  (formEntity) => formEntity?.isAutoLock,
);
export const selectFormEntityAutoLockInterval = createSelector(
  selectFormEntity,
  (formEntity) => formEntity?.autoLockInterval,
);
export const selectFormEntityAutoLock = createSelector(
  selectFormEntity,
  (formEntity) => formEntity?.autoLock,
);

export const selectFormEntityConstraint = createSelector(selectFormEntity, (formEntity) =>
  formEntity?.globalConstraint ? JSON.parse(formEntity.globalConstraint) : {},
);

export const selectLayouts = createSelector(selectFormEntity, (formEntity) => formEntity?.layouts);

export const selectCurrentLayoutIndex = createSelector(
  selectThis,
  (state) => state.currentLayoutIndex,
);

export const selectCurrentLayout = createSelector(
  selectFormEntity,
  selectCurrentLayoutIndex,
  (formEntity, layoutIndex) => formEntity?.layouts?.[layoutIndex],
);

export const selectCurrentLayoutSchema = createSelector(
  selectCurrentLayout,
  (layout) => layout?.schema,
);

export const selectWorkspaceMode = createSelector(selectThis, (state) => state.workspaceMode);
export const selectDeviceMode = createSelector(selectThis, (state) => state.deviceMode);
export const selectJSONMode = createSelector(selectThis, (state) => state.jsonMode);
export const selectSplitLayout = (state) => state.formEntity.splitLayout;

export const selectUndoDisabled = createSelector(
  selectThis,
  (state) =>
    !state.undoStack.length ||
    (state.workspaceMode !== FORM_DESIGNER_WORKSPACE_MODE.design &&
      state.workspaceMode !== FORM_DESIGNER_WORKSPACE_MODE.split),
);

export const selectRedoDisabled = createSelector(
  selectThis,
  (state) =>
    !state.redoStack.length ||
    (state.workspaceMode !== FORM_DESIGNER_WORKSPACE_MODE.design &&
      state.workspaceMode !== FORM_DESIGNER_WORKSPACE_MODE.split),
);

export const selectSelectedField = createSelector(selectThis, (state) => state.selectedField);

export const selectIsInnerEntity = (state) => {
  const { context, formEntity } = state;
  return formEntity.isInnerEntity ?? context.isInnerEntity;
};

export const selectUsingNewOrgTreeDataSecurity = createSelector(
  selectFormEntity,
  (formEntity) => formEntity.usingNewOrgTreeDataSecurity,
);

export const selectDataSecurity = createSelector(
  selectFormEntity,
  (formEntity) => formEntity.dataSecurity,
);

export const selectFormEntityFields = createSelector(
  selectFormEntity,
  (formEntity) => formEntity.fields,
);

export const selectFormEntityField = createSelector(
  [selectFormEntityFields, (state, fieldToken) => fieldToken],
  (fields, fieldToken) => fields.find((field) => field.token === fieldToken),
);

export const selectNonPublicFieldCount = createSelector(
  selectFormEntityFields,
  (fields) => fields.filter((field) => !field.source).length,
);

export const selectHasPendingField = createSelector(selectFormEntityFields, (fields) =>
  fields.some((field) => field.approvalStatus === 'WAIT_APPROVAL'),
);

export const selectFieldsOptions = createSelector(
  selectFormEntityFields,
  (fields) =>
    (fields || []).map((f) => {
      const value = f.token;
      const label = f.name || value;
      return {
        value,
        label,
      };
    }),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const selectPbcList = (state) => state.formEntity.pbcList;
export const selectFormList = (state) => state.formEntity.formList;
export const selectFormListInProject = (state) => state.formEntity.formListInProject;
export const selectPageList = (state) => state.formEntity.pageList;
export const selectPageListInProject = (state) => state.formEntity.pageListInProject;
export const selectFlowList = (state) => state.formEntity.flowList;
export const selectFlowListInProject = (state) => state.formEntity.flowListInProject;

export const selectBusinessFields = createSelector(selectThis, (state) => state.businessFields);
export const selectBusinessField = createSelector(
  [selectThis, (state, source) => source],
  (state, source) => state.businessFields.find((field) => field.uuid === source),
);
export const selectSavedTemplates = createSelector(selectThis, (state) => state.savedTemplates);
export const selectDataSourceAggregateListByApi = createSelector(
  selectThis,
  (state) => state.dataSourceAggregateListByApi,
);
export const selectDataSources = (scope) => {
  if (scope === 'pbc') {
    return (state) => state.formEntity.formList;
  }

  if (scope === 'project') {
    return (state) => state.formEntity.formListInProject;
  }

  if (scope === 'api') {
    return (state) => state.formEntity.dataSourceListByApi;
  }

  // eslint-disable-next-line react/function-component-definition
  return () => null;
};
export const selectDataSourceOption =
  ({ dataSource, dataUrl }) =>
  // eslint-disable-next-line react/function-component-definition
  (state) => {
    const scope = getDataSourceScope(dataSource, dataUrl);
    const dataSources = selectDataSources(scope)(state);

    if (scope === 'pbc') {
      return dataSources.find((item) => item.token === dataSource?.token);
    }

    if (scope === 'project') {
      return dataSources.find((item) => {
        return item.token === dataSource?.token && item.pbcToken === dataSource?.pbcToken;
      });
    }

    if (scope === 'api') {
      return dataSources.find((item) => item.dataUrl === dataUrl);
    }

    return null;
  };

export const selectRemoteContentVersion = (state) => state.formEntity.remoteContentVersion;

/// --- export async thunks ---

export const fetchBusinessFields = createAsyncThunk(
  'formEntity/fetchBusinessFields',
  async (payload, { signal, extra }) => {
    return extra.restApi
      .get(`/form/api/v2/form-entity-data/data-dictionary/data-dictionary-field-form/list`, {
        signal,
        params: {
          payload: JSON.stringify({
            filterModel: [
              {
                colId: 'approvalStatus',
                type: 'equals',
                filter: 'APPROVAL',
              },
            ],
          }),
        },
      })
      .then((res) => res?.results ?? [])
      .then((list) => list.sort((a, b) => a.id - b.id));
  },
);

export const fetchSavedTemplates = createAsyncThunk(
  'formEntity/fetchSavedTemplates',
  async (payload, { signal, extra }) => {
    return extra.restApi
      .get(`/form/api/template-ui/list`, { signal })
      .then((results) => results ?? []);
  },
);

export function saveTemplate(payload) {
  return (dispatch, getState, extra) => {
    return extra.restApi.post(`/form/api/template-ui`, payload);
  };
}

export function removeTemplate(id) {
  return (dispatch, getState, extra) => {
    return extra.restApi.delete(`/form/api/template-ui/${id}`);
  };
}

export const fetchPbcList = createAsyncThunk(
  'formEntity/fetchPbcList',
  async (payload, { getState, extra }) => {
    const state = getState();
    const pbc = selectPbc(state);
    return extra.restApi.get(`/form/api/pbc/list-by-project-id/${pbc.projectId}`);
  },
);

export const fetchFormList = createAsyncThunk(
  'formEntity/fetchFormList',
  async (payload, { getState, signal, extra }) => {
    const state = getState();
    const { id: pbcId, projectId } = selectPbc(state);
    return extra.restApi
      .get(`/form/api/form-entity/list-by-project-id/${projectId}`, { signal })
      .then((res) => {
        return [res.filter((item) => item.pbcId === pbcId), res];
      });
  },
);

export const fetchPageList = createAsyncThunk(
  'formEntity/fetchPageList',
  async (payload, { getState, signal, extra }) => {
    const state = getState();
    const { id: pbcId, projectId } = selectPbc(state);
    return extra.restApi
      .get(`/form/api/form-entity-page/list-by-project-id/${projectId}`, { signal })
      .then((res) => {
        return [res.filter((item) => item.pbcId === pbcId), res];
      });
  },
);

export const fetchFlowList = createAsyncThunk(
  'formEntity/fetchFlowList',
  async (payload, { getState, signal, extra }) => {
    const state = getState();
    const { id: pbcId, projectId } = selectPbc(state);
    return extra.restApi
      .get(`/flow/api/flow-definition/list-by-project-id/${projectId}`, { signal })
      .then((res) => {
        return [res.filter((item) => item.pbcId === pbcId), res];
      });
  },
);

export const fetchDataSourceListByProject = createAsyncThunk(
  'formEntity/fetchDataSourceListByProject',
  async (payload, { getState, signal, extra }) => {
    const { token } = selectProject(getState());
    return Promise.all([
      extra.restApi
        .get(`/flow/api/datasource/list-by-project-token/${token}`, { signal })
        .then((list) => {
          // fields 不是 designer 可用的 fields 格式，需要单独发请求取，先清空。
          return (list.results || []).map((item) => {
            item.fields = null;
            return item;
          });
        }),
      extra.restApi
        .get(`/flow/api/datasource/list-aggregate-by-project-token/${token}`, { signal })
        .then((list) => {
          // fields 不是 designer 可用的 fields 格式，需要单独发请求取，先清空。
          return (list.results || []).map((item) => {
            item.fields = null;
            return item;
          });
        }),
    ]);
  },
);

export const fetchFormEntityFields = createAsyncThunk(
  'formEntity/fetchFormEntityFields',
  async (payload, { signal, extra }) => {
    const { id } = payload;
    return extra.restApi
      .get(`/form/api/form-entity-field/list/${id}`, { signal })
      .then((list) => list ?? []);
  },
);

export const fetchFieldsByApiDataSource = createAsyncThunk(
  'formEntity/fetchFieldsByApiDataSource',
  async (payload, { signal, extra }) => {
    const { projectToken, token } = payload;
    return extra.restApi
      .get(
        `/flow/api/datasource-field/list-entity-field-by-data-source-token/${projectToken}/${token}`,
        {
          signal,
        },
      )
      .then((list) => list ?? []);
  },
);

export const fetchFormEntityById = createAsyncThunk(
  'formEntity/fetchFormEntityById',
  async (payload, { signal, extra }) => {
    const { id } = payload;
    return extra.restApi
      .get(`/form/api/form-entity/${id}`, { signal })
      .then(Converter.FormEntity.fromDTO)
      .then(legacySupportEntity);
  },
);

export const fetchFormEntity = createAsyncThunk(
  'formEntity/fetchFormEntity',
  async (payload, { signal, extra, getState }) => {
    const formEntityId = selectFormEntityId(getState());
    if (!formEntityId) return null;
    return extra.restApi
      .get(`/form/api/form-entity/${formEntityId}`, { signal })
      .then(Converter.FormEntity.fromDTO)
      .then((originalFormEntity) => {
        const formEntity = legacySupportEntity(JSON.parse(JSON.stringify(originalFormEntity)));
        return { formEntity, originalFormEntity };
      });
  },
);

export const createFormEntity = createAsyncThunk(
  'formEntity/createFormEntity',
  async (payload, { extra, getState }) => {
    const forWhat = payload;
    const formEntity = selectFormEntity(getState());
    const formListInProject = selectDataSources('project')(getState());
    const copy = legacySupportEntity(JSON.parse(JSON.stringify(formEntity)), false);
    const dto = Converter.FormEntity.toDTO(copy);
    const url =
      forWhat === 'forPublish'
        ? '/form/api/form-entity/create-form-entity-and-push-business-field'
        : '/form/api/form-entity/create-form-entity';

    const { newFormEntity, remoteToUpdate } = await handleReferenceReciprocal(
      dto,
      formListInProject,
    );

    const data = await extra.restApi.post(url, newFormEntity).then(Converter.FormEntity.fromDTO);

    await saveRemoteReference(remoteToUpdate);

    return data;
  },
);

export function createFormEntityByLayout({ layoutSchema, pbc }) {
  // equivalent to setCurrentLayoutSchema
  let formEntity = makeNewFormEntity(pbc.id, false, undefined, undefined);
  formEntity.name = layoutSchema.name || `Form-${+new Date()}`;
  formEntity.token = layoutSchema.token || `form-token-${+new Date()}`;
  formEntity.description = layoutSchema.description;
  Reflect.deleteProperty(layoutSchema, 'name');
  Reflect.deleteProperty(layoutSchema, 'token');
  Reflect.deleteProperty(layoutSchema, 'description');
  formEntity.pbcToken = pbc.token;
  formEntity.layouts[0].schema = layoutSchema;
  formEntity = syncSchemaAllInputFields(formEntity.layouts[0], formEntity);

  // equivalent to createFormEntity
  formEntity = legacySupportEntity(formEntity, false);
  const dto = Converter.FormEntity.toDTO(formEntity);
  const url = '/form/api/form-entity/create-form-entity';
  return restApi.post(url, dto);
}

function saveRemoteReference(remoteToUpdate) {
  return remoteToUpdate.map((entity) => {
    return restApi.put(`/form/api/form-entity/update-form-entity/${entity.id}`, entity);
  });
}

export const updateFormEntity = createAsyncThunk(
  'formEntity/updateFormEntity',
  async (payload, { extra, getState, rejectWithValue }) => {
    const forWhat = payload;
    const formEntity = selectFormEntity(getState());
    const formListInProject = selectDataSources('project')(getState());
    const subFormEntitiesDraft = selectSubFormEntitiesDraft(getState());
    const copy = legacySupportEntity(JSON.parse(JSON.stringify(formEntity)), false);
    const dto = Converter.FormEntity.toDTO(copy);
    const url =
      forWhat === 'forPublish'
        ? '/form/api/form-entity/update-form-entity-and-push-business-field'
        : '/form/api/form-entity/update-form-entity';

    const { newFormEntity, remoteToUpdate } = await handleReferenceReciprocal(
      dto,
      formListInProject,
    );

    try {
      const data = await extra.restApi
        .put(`${url}/${formEntity.id}`, newFormEntity)
        .then(Converter.FormEntity.fromDTO);

      if (subFormEntitiesDraft?.length) {
        await Promise.allSettled(
          subFormEntitiesDraft
            .map((subFormEntity) => Converter.FormEntity.toDTO(subFormEntity))
            .map((subFormEntity) => {
              return extra.restApi.post(`/form/api/form-entity/create-form-entity`, subFormEntity, {
                skipResponseInterceptors: true,
              });
            }),
        );
      }

      await saveRemoteReference(remoteToUpdate);

      return data;
    } catch (err) {
      // 10000 means contentVersion not match
      if (err.errorCode === '10000') {
        const newRemoteContentVersion = await extra.restApi.get(
          `/form/api/form-entity/${formEntity.id}/content-version`,
        );
        return rejectWithValue({ ...err, newRemoteContentVersion });
      }
      return rejectWithValue(err);
    }
  },
);

export const exportFormEntity = () => {
  return (dispatch, getState) => {
    const data = selectFormEntity(getState());
    saveAsJson(data, data.name);
  };
};

export const generateFieldTokenByTitle = createAsyncThunk(
  'formEntity/generateFieldTokenByTitle',
  async (payload, { getState, dispatch, extra }) => {
    const { keyPath, title } = payload;
    const { formEntity } = getState();
    const { businessFields } = formEntity;
    const res = await extra.restApi.post(`/aip/api/gpt/generate-token`, { content: title });
    if (!res?.token) {
      throw Error(`Failed to generate ID (token) for the field title "${title}".`);
    }
    const token = camelCase(res.token);
    dispatch(changeSetting({ fieldKeyPath: keyPath, keyPath: 'id', newValue: token }));
    const matchedField = businessFields.find((bizField) => bizField.token === token);
    if (matchedField) {
      dispatch(setFieldFromBizField({ keyPath, field: matchedField }));
    }
    return token;
  },
);

/// --- export async thunks end ---

const initSelectedField = {
  type: FIELD_TYPE_FORM,
  // keyPath 一定是指向的 FormRenderer 里的 field (element/layout/wrapper)，因为 table column 等
  // 这种三方组件无法把详细的列的 keyPath 写入到真实 div，所以需要靠额外的属性 colId 等来辅助
  keyPath: [],
  // type 是 FIELD_TYPE_TABLE_COLUMN 时生效。当通过 PanelCanvas 点击 ag-grid 选中的时候，就必须要靠 colId 来定位，因为 ag-grid div 里的 aria-colIndex 并不是当前列在 columnDefs 里真正的 colIndex
  colId: null,
  // type 是 FIELD_TYPE_TABLE_COLUMN 时生效。当通过 json 模式和 schema tree view 选中直接知道 colIndex 的，直接用就可以；
  // 同时 columnDefs 里的配置可能没有 colId，必须通过 colIndex 来定位。
  colIndex: null,
  // type 是 FIELD_TYPE_TABLE_COLUMN 时生效。Whether selected column is in EditableTable when type is FIELD_TYPE_TABLE_COLUMN
  isEditableTable: null,
  // type 是 FIELD_TYPE_TABLE_COLUMN 时生效。Whether selected column is componentProps.autoGroupColumnDef
  isAutoGroupColColumn: null,
  // Index of item when selected component is Steps / Collapse / Tabs, etc
  itemIndex: null,
};

const slice = createSlice({
  name: 'formEntity',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isSubmittingFor: null,
    isError: false,
    error: null,

    isAIGenerating: false,

    pbcList: null,
    formList: [],
    formListInProject: [],
    pageList: null,
    pageListInProject: null,
    flowList: null,
    flowListInProject: null,
    dataSourceListByApi: [],
    dataSourceAggregateListByApi: [],

    businessFields: [],
    savedTemplates: null,

    workspaceMode: FORM_DESIGNER_WORKSPACE_MODE.design,
    deviceMode: FORM_DESIGNER_DEVICE_MODE.desktop,
    jsonMode: { enabled: false, stage: null },
    splitLayout: localStorage.getItem(SPLIT_LAYOUT_SETTING_KEY) || 'horizontal',

    undoStack: [],
    redoStack: [],

    // AI产生的可编辑表格子表FormEntity, 提交同时一起创建
    subFormEntitiesDraft: [],

    originalFormEntity: null,
    formEntity: null,

    currentLayoutIndex: null,

    selectedField: { ...initSelectedField },

    remoteContentVersion: null,
  },
  reducers: {
    initFormEntity: (state, action) => {
      const { entity, originalEntity, pbcId, isInnerEntity, initialName, defaultButton } =
        action.payload;
      const formEntity =
        entity || makeNewFormEntity(pbcId, isInnerEntity, initialName, defaultButton);

      state.originalFormEntity = originalEntity || formEntity;
      state.formEntity = formEntity;
      state.remoteContentVersion = formEntity.contentVersion;
      state.currentLayoutIndex = 0;
    },
    importFormEntity: (state, action) => {
      const importData = { ...action.payload };

      delete importData.id;
      delete importData.pbcId;
      delete importData.contentVersion;

      // 判断一下有才赋值，只是为了解决一下在导入内容一样的时候不 dirty
      if ('id' in state.formEntity) {
        importData.id = state.formEntity.id;
      }
      if ('pbcId' in state.formEntity) {
        importData.pbcId = state.formEntity.pbcId;
      }
      if ('contentVersion' in state.formEntity) {
        importData.contentVersion = state.formEntity.contentVersion;
      }
      importData.layouts = (importData.layouts || []).map((importLayout) => {
        const oldLayout = state.formEntity.layouts.find((item) => item.id === importLayout.id);
        delete importLayout.id;
        // 如果 id 相同就不用清空保留原有的 layout id 做更新用，否则则清空 id 作为新增的 layout
        if (oldLayout) {
          importLayout.id = oldLayout.id;
        }
        return importLayout;
      });

      state.formEntity = importData;
      state.currentLayoutIndex = 0;
    },
    setSubFormEntitiesDraft: (state, action) => {
      state.subFormEntitiesDraft = action.payload || [];
    },
    setCurrentLayoutSchema: (state, action) => {
      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];
      currentLayout.schema = action.payload;
      state.formEntity = syncSchemaAllInputFields(currentLayout, state.formEntity);
    },
    modifyCurrentLayoutSchema: (state, action) => {
      const { keyPath, newValue } = action.payload;
      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];
      set(currentLayout.schema, keyPath, newValue);
      state.formEntity = syncSchemaAllInputFields(currentLayout, state.formEntity);
    },
    modifyEntityConstraint: (state, action) => {
      const newValue = action.payload;
      state.formEntity.globalConstraint = JSON.stringify(newValue || {});
    },
    inferDefaultLayoutAsCurrent: (state) => {
      const idx = state.formEntity?.layouts?.findIndex((layout) => layout.defaultLayout) ?? -1;
      if (idx > -1) {
        state.currentLayoutIndex = idx;
      } else {
        state.currentLayoutIndex = 0;
      }
    },
    setCurrentLayoutIndex: (state, action) => {
      state.currentLayoutIndex = action.payload;
      state.selectedField = { ...initSelectedField };
    },
    setFormEntity: (state, action) => {
      state.formEntity = action.payload;
      state.remoteContentVersion = action.payload.contentVersion;
      state.currentLayoutIndex = 0;
    },
    pushUndoStack: (state, action) => {
      const { undoStack } = state;
      undoStack.push(action.payload);
      if (undoStack.length > UNDO_STACK_MAX) {
        undoStack.shift();
      }
      state.redoStack = [];
    },
    undo: (state) => {
      const { formEntity, selectedField, undoStack, redoStack, currentLayoutIndex } = state;

      if (!undoStack.length) return;

      const currentLayout = formEntity.layouts[currentLayoutIndex];

      // TODO, undo redo 的时候 select field 严格来说是有点错位的，不完全是当前发生改变的 field
      redoStack.push({ formEntity, selectedField });

      const previous = undoStack.pop();
      state.formEntity = {
        ...previous.formEntity,
        contentVersion: state.formEntity.contentVersion,
      };
      state.selectedField = previous.selectedField;
      // 因为 currentLayout 不在 undo redo stack 里，所以这里恢复一下 currentLayout，防止 move layout 过后
      // 再 undo currentLayoutIndex 不变，选中的 layout 发生了变化不符合直觉。
      state.currentLayoutIndex = state.formEntity.layouts.findIndex((item) => {
        return item.token === currentLayout.token;
      });
    },
    redo: (state) => {
      const { formEntity, selectedField, undoStack, redoStack, currentLayoutIndex } = state;

      if (!redoStack.length) return;

      const currentLayout = formEntity.layouts[currentLayoutIndex];

      undoStack.push({ formEntity, selectedField });

      const next = redoStack.pop();
      state.formEntity = { ...next.formEntity, contentVersion: state.formEntity.contentVersion };
      state.selectedField = next.selectedField;
      state.currentLayoutIndex = state.formEntity.layouts.findIndex((item) => {
        return item.token === currentLayout.token;
      });
    },
    changeFormEntityBasicInfo: (state, action) => {
      const {
        domain,
        module,
        name,
        token,
        description,
        isMasterData,
        masterDataType,
        isDeletableWhenReferenced,
      } = action.payload;
      const { formEntity } = state;

      if (name) {
        formEntity.name = name;
      }
      if (token) {
        formEntity.token = token;
      }
      // action.payload 至少都会是 ''，formEntity.description 如果是 null 的话不去修改，造成无意义的 dirty
      if (formEntity.description || description) {
        formEntity.description = description;
      }
      if (formEntity.isMasterData !== isMasterData) {
        formEntity.isMasterData = isMasterData;
      }
      if (formEntity.masterDataType !== masterDataType) {
        formEntity.masterDataType = masterDataType;
      }
      if (domain !== formEntity.domain && !(!formEntity.domain && !domain)) {
        formEntity.domain = domain;

        // populate domain to fields
        const fieldsNoDomain = formEntity.fields.filter(
          (field) => field.domain == null || field.domain === '',
        );
        fieldsNoDomain.forEach((field) => {
          field.domain = domain;
        });
        formEntity.layouts.forEach((layout) => {
          forEachInputFieldInSchema(layout.schema, (field) => {
            field.domain = domain;
          });
        });
      }
      if (module !== formEntity.module && !(!formEntity.module && !module)) {
        formEntity.module = module;

        // populate module to fields
        const fieldsNoModule = formEntity.fields.filter(
          (field) => field.module == null || field.module === '',
        );
        fieldsNoModule.forEach((field) => {
          field.module = module;
        });
        formEntity.layouts.forEach((layout) => {
          forEachInputFieldInSchema(layout.schema, (field) => {
            field.module = module;
          });
        });
      }
      if (formEntity.isDeletableWhenReferenced !== isDeletableWhenReferenced) {
        formEntity.isDeletableWhenReferenced = isDeletableWhenReferenced;
      }
    },
    setWorkspaceMode: (state, action) => {
      state.workspaceMode = action.payload;
    },
    setDeviceMode: (state, action) => {
      state.deviceMode = action.payload;
    },
    setJSONMode: (state, action) => {
      state.jsonMode = action.payload;
    },
    setSplitLayout: (state, action) => {
      state.splitLayout = action.payload;
    },
    setSelectedField: (state, action) => {
      if (!isEqual(state.selectedField, action.payload)) {
        if (typeof action.payload === 'object' && !Object.keys(action.payload).length) {
          // ui 操作例如按 esc 传 {} 表示清空选择，现在这里行为改为始终至少选中 form，强调 form 本身的设置，显示一个空白没有意义
          state.selectedField = { ...initSelectedField };
        } else {
          state.selectedField = action.payload;
        }
      }
    },
    setRemoteContentVersion: (state, action) => {
      state.remoteContentVersion = action.payload;
    },
    addNewLayout: (state, action) => {
      const {
        newLayoutName,
        newLayoutToken,
        isDefaultLayout,
        isDefaultViewLayout,
        isDefaultMobileViewLayout,
        componentLibrary,
      } = action.payload;
      const { layouts } = state.formEntity;
      const defaultLayout = layouts.find((layout) => layout.defaultLayout);
      const newLayout = {
        defaultLayout: isDefaultLayout,
        name: newLayoutName,
        token: newLayoutToken,
        schema: JSON.parse(JSON.stringify(defaultLayout.schema)),
      };

      if (isDefaultLayout) {
        layouts.forEach((item) => {
          item.defaultLayout = false;
        });
      }

      if (isDefaultViewLayout) {
        state.formEntity.defaultViewLayoutToken = newLayoutToken;
      }

      if (isDefaultMobileViewLayout) {
        state.formEntity.defaultMobileViewLayoutToken = newLayoutToken;
      }

      // 将当前 layout name 作为 page title
      // TODO, 暂时只应用给新的 material Tempalte3，不影响老项目，后面问产品经历是否要应用给所有项目
      if (componentLibrary === 'material-ui') {
        newLayout.schema = updateFieldOperation(
          newLayout.schema,
          [],
          ['form', 'title'],
          newLayoutName,
        );
      }

      layouts.push(newLayout);
      state.currentLayoutIndex = layouts.length - 1;
    },
    deleteLayout: (state, action) => {
      const { formEntity, currentLayoutIndex } = state;
      if (state.formEntity.layouts.length === 1) {
        return;
      }
      state.formEntity.layouts.splice(action.payload, 1);
      state.formEntity.fields = filterFieldsNotInAnyLayouts(formEntity.fields, formEntity.layouts);
      if (currentLayoutIndex === action.payload) {
        state.currentLayoutIndex = 0;
      } else if (currentLayoutIndex > action.payload) {
        state.currentLayoutIndex -= 1;
      }

      if (!state.formEntity.layouts.find((x) => x.defaultLayout)) {
        state.formEntity.layouts[0].defaultLayout = true;
      }
    },
    changeLayoutNameAndToken: (state, action) => {
      const {
        oldLayoutName,
        newLayoutToken,
        newLayoutName,
        isDefaultLayout,
        isDefaultViewLayout,
        isDefaultMobileViewLayout,
        componentLibrary,
      } = action.payload;

      const { layouts } = state.formEntity;
      const targetLayout = state.formEntity.layouts.find((layout) => layout.name === oldLayoutName);
      if (!targetLayout) return;

      targetLayout.name = newLayoutName;
      targetLayout.token = newLayoutToken;

      if (isDefaultLayout) {
        layouts.forEach((item) => {
          item.defaultLayout = false;
        });
        targetLayout.defaultLayout = true;
      }

      if (isDefaultViewLayout) {
        state.formEntity.defaultViewLayoutToken = newLayoutToken;
      }

      if (isDefaultMobileViewLayout) {
        state.formEntity.defaultMobileViewLayoutToken = newLayoutToken;
      }

      const oldPageTitle = targetLayout.schema?.form?.title;

      // 将当前 layout name 作为 page title
      // TODO, 暂时只应用给新的 material Tempalte3，不影响老项目，后面问产品经历是否要应用给所有项目
      if (componentLibrary === 'material-ui' && (!oldPageTitle || oldPageTitle === oldLayoutName)) {
        targetLayout.schema = updateFieldOperation(
          targetLayout.schema,
          [],
          ['form', 'title'],
          newLayoutName,
        );
      }
    },
    moveLayout: (state, action) => {
      // 将 fromIndex 位置的 layout 放到 toIndex 的位置
      const { fromIndex, toIndex } = action.payload;
      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];
      const newLayouts = immutableMove(state.formEntity.layouts, fromIndex, toIndex);
      state.formEntity.layouts = newLayouts;
      state.currentLayoutIndex = newLayouts.findIndex((item) => item.token === currentLayout.token);
    },
    addField: (state, action) => {
      const { source, target } = action.payload;
      const { type, value } = source;
      // when keyPath === [] means append to top of schema
      const { keyPath: keyPathField, operation, isBefore, itemIndex } = target;
      const { domain, module, token: formEntityToken, pbcToken } = state.formEntity;

      const newField =
        (type === 'component' &&
          makeNewField(value, { pbcToken, formEntityToken, domain, module })) ||
        (type === 'token' && makeFieldFromToken(state.formEntity, value)) ||
        (type === 'businessField' && makeNewFieldFromBusinessField(value)) ||
        (type === 'snippet' && makeNewFieldFromSnippet(value)) ||
        null;

      if (!newField) {
        return;
      }

      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];

      // handle drag to Collapse / Tabs items
      let keyPath = keyPathField;
      if ([FIELD_TYPE_COLLAPSE_ITEM, FIELD_TYPE_TAB].includes(target.type)) {
        keyPath = keyPath.concat('componentProps', 'items', itemIndex);
      }

      // Do append
      const { newSchema, newKeyPath } =
        operation === 'append'
          ? appendFieldOperation(currentLayout.schema, keyPath, newField)
          : insertFieldOperation(currentLayout.schema, keyPath, newField, isBefore);

      currentLayout.schema = newSchema;

      // Handle database fields
      if (
        isInputField(newField) &&
        newField.id &&
        state.formEntity.fields.every((field) => field.token !== newField.id)
      ) {
        const dbFieldSource = type === 'businessField' ? value?.uuid : undefined;
        state.formEntity = insertNewFieldToFields(state.formEntity, newField, dbFieldSource);
      }

      state.selectedField = {
        keyPath: newKeyPath,
      };
    },
    moveField: (state, action) => {
      const { source, target } = action.payload;
      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];

      const { newSchema, newKeyPath } = moveFieldOperation(currentLayout.schema, source, target);

      currentLayout.schema = newSchema;
      state.selectedField = { ...state.selectedField, keyPath: newKeyPath };
    },
    removeSelf: (state) => {
      const { formEntity, selectedField } = state;
      const currentLayout = formEntity.layouts[state.currentLayoutIndex];
      currentLayout.schema = removeSelfOperation(currentLayout.schema, selectedField.keyPath);
      state.selectedField = { ...initSelectedField };
    },
    deleteField: (state) => {
      const { formEntity, selectedField } = state;
      const currentLayout = formEntity.layouts[state.currentLayoutIndex];

      currentLayout.schema = deleteFieldOperation(currentLayout.schema, selectedField.keyPath);
      state.formEntity.fields = filterFieldsNotInAnyLayouts(formEntity.fields, formEntity.layouts);
      state.selectedField = { ...initSelectedField };
    },
    deleteFieldFromAllLayout: (state) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;

      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const fieldInfo = get(currentLayout.schema, selectedField.keyPath);
      const token = fieldInfo.id;

      // Delete from top data fields
      formEntity.fields = formEntity.fields.filter((item) => item.token !== token);

      // Delete from current layout
      currentLayout.schema = deleteFieldOperation(currentLayout.schema, selectedField.keyPath);

      // Delete from other layouts
      formEntity.layouts.forEach((layout, index) => {
        if (index !== currentLayoutIndex) {
          const { keyPath: fieldKeyPath } = findSchemaFieldByToken(layout.schema, token);
          layout.schema = deleteFieldOperation(layout.schema, fieldKeyPath);
        }
      });

      state.selectedField = { ...initSelectedField };
    },
    duplicateField: (state) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const { schema } = currentLayout;
      const { keyPath } = selectedField;

      const copiedField = get(schema, keyPath);
      const { newSchema, newKeyPath, addedDataFields } = pasteOperation(
        formEntity,
        schema,
        keyPath,
        copiedField,
        'after',
      );

      currentLayout.schema = newSchema;
      state.formEntity.fields = formEntity.fields.concat(addedDataFields);
      state.selectedField = {
        keyPath: newKeyPath,
      };
    },
    copyField: () => {
      // TODO, 如果需要禁止暴露 json 到 clipboard 的话就需要内部实现 copy，缺点是无法跨页面 paste
    },
    pasteField: (state, action) => {
      const { copiedField, pasteType } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;

      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const { schema } = currentLayout;
      const { keyPath } = selectedField;

      const { newSchema, newKeyPath, addedDataFields } = pasteOperation(
        formEntity,
        schema,
        keyPath,
        copiedField,
        pasteType,
      );

      currentLayout.schema = newSchema;
      state.formEntity.fields = formEntity.fields.concat(addedDataFields);
      state.selectedField = {
        type: !newKeyPath.length ? FIELD_TYPE_FORM : undefined,
        keyPath: newKeyPath,
      };
    },
    syncProperties: (state, action) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const { schema } = currentLayout;
      const { keyPath } = selectedField;

      const fieldInfo = get(schema, keyPath);
      const token = fieldInfo.id;
      state.formEntity = syncOneInputAllPropertiesToOtherLayouts({
        formEntity,
        currentLayout,
        token,
        tokens: action.payload,
      });
    },
    addTableColumn: (state, action) => {
      const { isBefore } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath, colIndex } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const colDefKeyPath = keyPath.concat('componentProps', 'columnDefs', colIndex);

      const newColumn = makeNewTableColumn();
      const { newSchema, newKeyPath: nextColKeyPath } = insertFieldOperation(
        currentLayout.schema,
        colDefKeyPath,
        newColumn,
        isBefore,
      );
      currentLayout.schema = newSchema;
      const nextColIndex = nextColKeyPath[nextColKeyPath.length - 1];
      const fieldInfo = get(newSchema, keyPath);
      const nexColId = colIndexToColId(fieldInfo, nextColIndex);
      state.selectedField = {
        ...state.selectedField,
        colId: nexColId,
        colIndex: nextColIndex,
        isAutoGroupColColumn: false,
      };
    },
    moveTableColumn: (state, action) => {
      const { isBefore, fromIndex, toIndex } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath, colIndex } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      if (fromIndex !== undefined) {
        const { newSchema } = moveOrderOperation(
          currentLayout.schema,
          keyPath.concat('componentProps', 'columnDefs'),
          fromIndex,
          toIndex,
        );
        currentLayout.schema = newSchema;
        state.selectedField = {
          ...state.selectedField,
          colIndex: toIndex,
        };
      } else {
        const { newSchema } = moveBeforeOrAfterOperation(
          currentLayout.schema,
          keyPath.concat('componentProps', 'columnDefs'),
          colIndex,
          isBefore,
        );

        currentLayout.schema = newSchema;
        state.selectedField = {
          ...state.selectedField,
          colIndex: colIndex + (isBefore ? -1 : 1),
        };
      }
    },
    deleteTableColumn: (state) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath, colIndex, isAutoGroupColColumn } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const colDefKeyPath = isAutoGroupColColumn
        ? keyPath.concat('componentProps', 'autoGroupColumnDef')
        : keyPath.concat('componentProps', 'columnDefs', colIndex);

      currentLayout.schema = deleteFieldOperation(currentLayout.schema, colDefKeyPath);
      state.selectedField = { ...initSelectedField };
    },
    addFieldItem: (state, action) => {
      const { isBefore } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { type, keyPath, itemIndex } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const fieldInfo = get(currentLayout.schema, keyPath);

      const stepKeyPath = keyPath.concat(['componentProps', 'items', itemIndex]);

      const nextIndex = itemIndex + (isBefore ? 0 : 1);
      const newItem =
        (type === FIELD_TYPE_STEP && makeNewStep(nextIndex)) ||
        (type === FIELD_TYPE_COLLAPSE_ITEM && makeNewCollapseStep(nextIndex)) ||
        (type === FIELD_TYPE_TAB && makeNewTab(nextIndex)) ||
        null;

      if (!newItem) {
        return;
      }

      let { newSchema } = insertFieldOperation(
        currentLayout.schema,
        stepKeyPath,
        newItem,
        isBefore,
      );

      if (type === FIELD_TYPE_COLLAPSE_ITEM) {
        // toggle collapse
        let defaultActiveKey = fieldInfo.componentProps?.defaultActiveKey;
        if (fieldInfo.componentProps?.accordion) {
          defaultActiveKey = newItem.key;
        } else {
          defaultActiveKey = [].concat(defaultActiveKey, newItem.key).filter(Boolean);
        }
        newSchema = updateFieldOperation(
          newSchema,
          selectedField.keyPath,
          ['componentProps', 'defaultActiveKey'],
          defaultActiveKey,
        );
      }

      currentLayout.schema = newSchema;
      state.selectedField = {
        ...state.selectedField,
        itemIndex: itemIndex + (isBefore ? 0 : 1),
      };
    },
    moveFieldItem: (state, action) => {
      const { isBefore } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath, itemIndex } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const { newSchema } = moveBeforeOrAfterOperation(
        currentLayout.schema,
        keyPath.concat('componentProps', 'items'),
        itemIndex,
        isBefore,
      );

      currentLayout.schema = newSchema;
      state.selectedField = {
        ...state.selectedField,
        itemIndex: itemIndex + (isBefore ? -1 : 1),
      };
    },
    deleteFieldItem: (state) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath, itemIndex } = selectedField;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const items = get(currentLayout.schema, keyPath.concat('componentProps', 'items'));
      const onlyOne = items.length === 1;

      if (onlyOne) {
        return;
      }

      const stepKeyPath = keyPath.concat('componentProps', 'items', itemIndex);

      currentLayout.schema = deleteFieldOperation(currentLayout.schema, stepKeyPath);
      state.selectedField = { ...initSelectedField };
    },
    embedIn: (state, action) => {
      const { name } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const { keyPath } = selectedField;

      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const parentKeyPath = keyPath.slice(0, keyPath.length - 2);
      const parentField = get(currentLayout.schema, parentKeyPath) || {};
      if (
        (name === 'Link' &&
          (parentField.component === 'Link' || parentField.component === 'LinkWrapper')) ||
        (name === 'Data' && parentField.component === 'Data') ||
        (name === 'Timer' && parentField.component === 'Timer')
      ) {
        console.warn(`The parent of the selected field is already ${name}.`);
        state.selectedField = {
          ...state.selectedField,
          keyPath: parentKeyPath,
        };
        return;
      }

      currentLayout.schema = embedFieldInOperation(currentLayout.schema, keyPath, name);
      state.selectedField = {
        ...state.selectedField,
      };
    },
    selectParent: (state) => {
      const { selectedField, formEntity, currentLayoutIndex } = state;
      const currentLayout = formEntity.layouts[currentLayoutIndex];

      const { keyPath } = selectedField;

      const parentKeyPath = keyPath.slice(0, keyPath.length - 2);

      // form
      if (!parentKeyPath.length) {
        state.selectedField = {
          type: FIELD_TYPE_FORM,
          keyPath: [],
        };
        return;
      }

      // normal field
      if (parentKeyPath[parentKeyPath.length - 2] !== 'items') {
        state.selectedField = {
          keyPath: parentKeyPath,
        };
        return;
      }

      // field in items
      const itemIndex = parentKeyPath[parentKeyPath.length - 1];
      const field = get(currentLayout.schema, parentKeyPath.slice(0, parentKeyPath.length - 3));
      if (field.component === 'Collapse') {
        state.selectedField = {
          type: FIELD_TYPE_COLLAPSE_ITEM,
          keyPath: parentKeyPath.slice(0, parentKeyPath.length - 3),
          itemIndex,
        };
      } else if (field.component === 'Tabs') {
        state.selectedField = {
          type: FIELD_TYPE_TAB,
          keyPath: parentKeyPath.slice(0, parentKeyPath.length - 3),
          itemIndex,
        };
      }
    },
    modifyDbField: (state, action) => {
      const { fieldToken, key, value } = action.payload;
      const field = state.formEntity.fields.find((x) => x.token === fieldToken);
      if (!field) return;
      set(field, key, value);
    },
    changeSetting: (state, action) => {
      /*
       * fieldKeyPath 目前主要是 generateFieldTokenByTitle() 在使用。由于触发该方法后需要异步生成token才会
       * 调用 changeSetting，这时 selectedField 有可能已经改变，因此需要 fieldKeyPath 来记录原本要修改的字段。
       */
      const {
        keyPath: propertyKeyPath,
        newValue,
        fieldKeyPath: payloadFieldKeyPath,
      } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;

      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const fieldKeyPath = payloadFieldKeyPath || selectedField.keyPath;

      // TODO, 用 type 来判断 form 每次都很麻烦，refactor keyPath 为 ['form'] 而不是 []
      const fieldInfo =
        !payloadFieldKeyPath && selectedField.type === FIELD_TYPE_FORM
          ? currentLayout.schema
          : get(currentLayout.schema, fieldKeyPath);

      // Backup id, propertyKeyPath maybe equals 'id', updateFieldOperation will change it
      const fieldId = fieldInfo?.id;

      currentLayout.schema = updateFieldOperation(
        currentLayout.schema,
        fieldKeyPath,
        propertyKeyPath,
        newValue,
      );

      const token = propertyKeyPath === 'id' ? newValue : fieldId;
      if (token && isInputField(fieldInfo)) {
        state.formEntity = syncOneInputCrossProperties({
          formEntity,
          currentLayout,
          token,
          needEnsureFields: propertyKeyPath === 'id',
        });
      }

      // CombinedView 下的 Table Gantt 等组件共享 width, height 等 style 更符合直觉
      const parentKeyPath = selectedField.keyPath.slice(0, selectedField.keyPath.length - 2);
      const parentField = get(currentLayout.schema, parentKeyPath) || {};
      if (parentField.component === 'CombinedView' && propertyKeyPath[0] === 'style') {
        parentField.fields.forEach((x, index) => {
          const nextFieldKeyPath = parentKeyPath.concat(['fields', index]);
          currentLayout.schema = updateFieldOperation(
            currentLayout.schema,
            nextFieldKeyPath,
            propertyKeyPath,
            newValue,
          );
        });

        // width height 同时设置到 CombinedView 的 div 上，否则在 Table 设置 100% 会失效
        if (['width', 'height'].includes(propertyKeyPath[1])) {
          currentLayout.schema = updateFieldOperation(
            currentLayout.schema,
            parentKeyPath,
            propertyKeyPath,
            newValue,
          );
        }
      }
    },
    // id 的更改不做update
    changeFieldAllProperties: (state, action) => {
      const { keyPath, newValue } = action.payload;
      const { formEntity, currentLayoutIndex } = state;
      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const selectedField = get(currentLayout.schema, keyPath);
      if (selectedField.id === newValue.id) {
        const newSchema = updateFieldOperation(currentLayout.schema, keyPath, [], newValue);
        currentLayout.schema = newSchema;
      }
    },
    setFieldFromBizField: (state, action) => {
      const { keyPath, field: bizField } = action.payload;
      const formEntity = state.formEntity;
      const currentLayout = state.formEntity.layouts[state.currentLayoutIndex];
      const currentLayoutField = get(currentLayout.schema, keyPath);

      const currentDbFieldIndex = formEntity.fields.findIndex(
        (field) => field.token === currentLayoutField.id,
      );

      const schemaField = makeNewFieldFromBusinessField(bizField, currentLayoutField);
      formEntity.fields[currentDbFieldIndex] = {
        ...schemaFieldToDataField(schemaField, formEntity.pbcToken),
        source: bizField.uuid,
      };

      const entryToSet = [];
      formEntity.layouts.forEach((layout) => {
        forEachInputFieldInSchema(layout.schema, (field, kp) => {
          if (field.id !== currentLayoutField.id) return;
          const newLayoutField = makeNewFieldFromBusinessField(bizField, field);
          entryToSet.push([layout.schema, kp, newLayoutField]);
        });
      });

      entryToSet.forEach((entry) => {
        set(...entry);
      });
    },
    // for json mode only!
    setDataSecurity: (state, action) => {
      state.formEntity.dataSecurity = action.payload;
    },
    changeUsingNewOrgTreeDataSecurity: (state, action) => {
      state.formEntity.usingNewOrgTreeDataSecurity = action.payload;
    },
    addNewDataSecurityItem: (state) => {
      if (!state.formEntity.dataSecurity) {
        state.formEntity.dataSecurity = [];
      }
      state.formEntity.dataSecurity.push({
        dataScope: DATA_SECURITY_SCOPE_SELF,
        dataPredicate: {
          operator: DATA_SECURITY_OPERATOR_AND,
          conditions: [],
        },
      });
    },
    deleteDataSecurityItem: (state, action) => {
      const { index } = action.payload;
      state.formEntity.dataSecurity.splice(index, 1);
    },
    changeDataScope: (state, action) => {
      const { index, value } = action.payload;
      state.formEntity.dataSecurity[index].dataScope = value;
    },
    changeSecurityItem: (state, action) => {
      const { index, value, label } = action.payload;
      state.formEntity.dataSecurity[index][label] = value;
    },
    changeDataSecurityOperator: (state, action) => {
      const { index, value } = action.payload;
      if (!state.formEntity.dataSecurity[index].dataPredicate) {
        state.formEntity.dataSecurity[index].dataPredicate = {};
      }
      state.formEntity.dataSecurity[index].dataPredicate.operator = value;
    },
    addNewDataSecurityCondition: (state, action) => {
      const { index } = action.payload;
      if (!state.formEntity.dataSecurity[index].dataPredicate.conditions) {
        state.formEntity.dataSecurity[index].dataPredicate.conditions = [];
      }
      state.formEntity.dataSecurity[index].dataPredicate.conditions.push({
        dataField: null,
        condition: DATA_SECURITY_OPERATOR_EQUALS,
        value: '',
      });
    },
    changeDataSecurityCondition: (state, action) => {
      const { index, dpcIndex, value } = action.payload;
      state.formEntity.dataSecurity[index].dataPredicate.conditions[dpcIndex].condition = value;
    },
    changeDataSecurityDataField: (state, action) => {
      const { index, dpcIndex, value } = action.payload;
      state.formEntity.dataSecurity[index].dataPredicate.conditions[dpcIndex].dataField = value;
    },
    changeDataSecurityConditionValue: (state, action) => {
      const { index, dpcIndex, value } = action.payload;
      state.formEntity.dataSecurity[index].dataPredicate.conditions[dpcIndex].value = value;
    },
    deleteDataSecurityCondition: (state, action) => {
      const { index, dpcIndex } = action.payload;
      state.formEntity.dataSecurity[index].dataPredicate.conditions.splice(dpcIndex, 1);
    },
    updateExplanation: (state, action) => {
      const { layoutId, explanation } = action.payload;
      const layout = state.formEntity.layouts.find((item) => item.id === layoutId);
      if (layout) {
        layout.schema.explanation = explanation;
      }
    },
    setReferenceReciprocal: (state, action) => {
      const { token, reciprocalDataField, referenceReciprocal } = action.payload;
      const { formEntity } = state;

      const thisDataField = formEntity.fields.find((field) => field.token === token);

      thisDataField.referenceReciprocal =
        referenceReciprocal || getReferenceReciprocal(reciprocalDataField);
    },
    addView: (state, action) => {
      const { component } = action.payload;
      const { selectedField, formEntity, currentLayoutIndex } = state;
      let { keyPath } = selectedField;

      const currentLayout = formEntity.layouts[currentLayoutIndex];
      const fieldInfo = get(currentLayout.schema, keyPath);
      const parentKeyPath = keyPath.slice(0, keyPath.length - 2);
      const parentField = get(currentLayout.schema, parentKeyPath) || {};

      const dataSource =
        (fieldInfo.component === 'Table' && fieldInfo.componentProps?.dataSource) ||
        (fieldInfo.component === 'Gantt' &&
          fieldInfo.componentProps?.dataSources?.task?.dataSource) ||
        null;
      const columnDefs = fieldInfo.componentProps?.columnDefs;
      const pinnedFilter = fieldInfo.componentProps?.pinnedFilter;
      const style = fieldInfo.style;

      if (parentField.component !== 'CombinedView') {
        currentLayout.schema = embedFieldInOperation(
          currentLayout.schema,
          keyPath,
          'CombinedView',
          style
            ? {
                width: style.width,
                height: style.height,
              }
            : null,
        );
        keyPath = keyPath.concat('fields', 0);
      }
      const newField = makeNewField(component);
      if (component === 'Table') {
        newField.componentProps.columnDefs = toTableColumnDefs(columnDefs);
        newField.componentProps.dataSource = dataSource;
        newField.componentProps.pinnedFilter = pinnedFilter;
        newField.style = style;
      }
      if (component === 'Gantt') {
        const entity = dataSource?.token
          ? state.formListInProject.find((x) => {
              return (
                x.token === dataSource.token &&
                (!dataSource.pbcToken || x.pbcToken === dataSource.pbcToken)
              );
            })
          : null;
        newField.componentProps.columnDefs = toGanttColumnDefs(columnDefs);
        newField.componentProps.dataSources = {
          task: {
            dataSource: entity
              ? {
                  token: entity.token,
                  layoutToken: entity.layouts?.[0]?.token,
                }
              : undefined,
          },
        };
        newField.componentProps.pinnedFilter = pinnedFilter;
        newField.style = style;
      }

      const { newSchema, newKeyPath } = insertFieldOperation(
        currentLayout.schema,
        keyPath,
        newField,
        false,
      );

      currentLayout.schema = newSchema;
      state.selectedField = {
        keyPath: newKeyPath,
      };
    },
    setIsAutoLock: (state, action) => {
      state.formEntity.isAutoLock = action.payload;
      // 开启表单自动锁定时, 给所有layout添加默认自动锁定, 之前用户手动配false的不修改
      if (action.payload) {
        state.formEntity.layouts.forEach((layout) => {
          if (
            get(layout, 'schema.form.enableAutoLock') == null &&
            state.formEntity.defaultViewLayoutToken !== layout.token &&
            state.formEntity.defaultMobileViewLayoutToken !== layout.token
          ) {
            set(layout, 'schema.form.enableAutoLock', true);
          }
        });
      }
    },
    setAutoLockInterval: (state, action) => {
      state.formEntity.autoLockInterval = action.payload;
    },
    changeAutoLockItem: (state, action) => {
      const { index, key, value } = action.payload;
      state.formEntity.autoLock[index][key] = value;
    },
    deleteAutoLockItem: (state, action) => {
      const { index } = action.payload;
      state.formEntity.autoLock.splice(index, 1);
    },
    addAutoLockItem: (state) => {
      if (!state.formEntity.autoLock) {
        state.formEntity.autoLock = [];
      }
      state.formEntity.autoLock.push({
        colId: null,
        filterType: 'set',
        values: [],
      });
    },
    setAIGenerating: (state, action) => {
      state.isAIGenerating = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPbcList.fulfilled, (state, action) => {
        state.pbcList = action.payload;
      })
      .addCase(fetchBusinessFields.fulfilled, (state, action) => {
        state.businessFields = action.payload;
      })
      .addCase(fetchSavedTemplates.fulfilled, (state, action) => {
        state.savedTemplates = action.payload;
      })
      .addCase(fetchFormList.fulfilled, (state, action) => {
        [state.formList, state.formListInProject] = action.payload;
      })
      .addCase(fetchPageList.fulfilled, (state, action) => {
        [state.pageList, state.pageListInProject] = action.payload;
      })
      .addCase(fetchFlowList.fulfilled, (state, action) => {
        [state.flowList, state.flowListInProject] = action.payload;
      })
      .addCase(fetchDataSourceListByProject.fulfilled, (state, action) => {
        [state.dataSourceListByApi, state.dataSourceAggregateListByApi] = action.payload;
      })
      .addCase(fetchFormEntityFields.fulfilled, (state, action) => {
        const form = state.formList.find((entity) => {
          return entity.id === action.meta.arg.id;
        });
        if (form) {
          form.fields = action.payload;
        }

        const formInProject = state.formListInProject.find((entity) => {
          return entity.id === action.meta.arg.id;
        });
        if (formInProject) {
          formInProject.fields = action.payload;
        }
      })
      .addCase(fetchFieldsByApiDataSource.fulfilled, (state, action) => {
        const dataSource = state.dataSourceListByApi.find((ds) => {
          return ds.token === action.meta.arg.token;
        });
        if (dataSource) {
          dataSource.fields = action.payload;
        }
      })
      .addCase(fetchFormEntityById.fulfilled, (state, action) => {
        const index = state.formList.findIndex((entity) => {
          return entity.id === action.meta.arg.id;
        });
        const indexInProject = state.formListInProject.findIndex((entity) => {
          return entity.id === action.meta.arg.id;
        });
        if (index !== -1) {
          state.formList[index] = action.payload;
        }
        if (indexInProject !== -1) {
          state.formListInProject[indexInProject] = action.payload;
        }
      })
      .addCase(fetchFormEntity.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchFormEntity.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(fetchFormEntity.fulfilled, (state, action) => {
        const { formEntity, originalFormEntity } = action.payload;

        state.isLoading = false;
        state.isError = false;
        state.error = null;

        state.originalFormEntity = originalFormEntity;
        state.formEntity = formEntity;
        state.remoteContentVersion = formEntity.contentVersion;
      })
      .addCase(createFormEntity.pending, (state, action) => {
        state.isSubmittingFor = action.meta.arg;
        state.isSubmitting = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(createFormEntity.rejected, (state, action) => {
        state.isSubmittingFor = null;
        state.isSubmitting = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(createFormEntity.fulfilled, (state, action) => {
        state.isSubmittingFor = null;
        state.isSubmitting = false;
        state.isError = false;
        state.error = null;

        state.originalFormEntity = action.payload;
        state.formEntity = action.payload;
        state.remoteContentVersion = action.payload.contentVersion;
      })
      .addCase(updateFormEntity.pending, (state, action) => {
        state.isSubmittingFor = action.meta.arg;
        state.isSubmitting = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(updateFormEntity.rejected, (state, action) => {
        state.isSubmittingFor = null;
        state.isSubmitting = false;
        state.isError = true;
        state.error = action.error;
        if (action.payload?.errorCode === '10000') {
          state.remoteContentVersion = action.payload.newRemoteContentVersion;
        }
      })
      .addCase(updateFormEntity.fulfilled, (state, action) => {
        state.isSubmittingFor = null;
        state.isSubmitting = false;
        state.isError = false;
        state.error = null;

        state.originalFormEntity = action.payload;
        state.formEntity = action.payload;
        state.remoteContentVersion = action.payload.contentVersion;
      });
  },
});
/// --- export actions ---

export const {
  initFormEntity,
  importFormEntity,
  setSubFormEntitiesDraft,
  setCurrentLayoutSchema,
  modifyCurrentLayoutSchema,
  modifyEntityConstraint,
  inferDefaultLayoutAsCurrent,
  setCurrentLayoutIndex,
  setFormEntity,
  pushUndoStack,
  undo,
  redo,
  changeFormEntityBasicInfo,
  setWorkspaceMode,
  setDeviceMode,
  setJSONMode,
  setSplitLayout,
  setSelectedField,
  setRemoteContentVersion,
  addNewLayout,
  deleteLayout,
  changeLayoutNameAndToken,
  moveLayout,
  addField,
  moveField,
  removeSelf,
  deleteField,
  deleteFieldFromAllLayout,
  duplicateField,
  copyField,
  pasteField,
  syncProperties,
  addTableColumn,
  moveTableColumn,
  deleteTableColumn,
  addFieldItem,
  moveFieldItem,
  deleteFieldItem,
  embedIn,
  selectParent,
  modifyDbField,
  changeSetting,
  changeFieldAllProperties,
  setFieldFromBizField,
  changeUsingNewOrgTreeDataSecurity,
  setDataSecurity,
  addNewDataSecurityItem,
  deleteDataSecurityItem,
  changeDataScope,
  changeSecurityItem,
  changeDataSecurityOperator,
  addNewDataSecurityCondition,
  changeDataSecurityCondition,
  changeDataSecurityDataField,
  changeDataSecurityConditionValue,
  deleteDataSecurityCondition,
  setSettingsPanelOpen,
  updateExplanation,
  setReferenceReciprocal,
  addView,
  setIsAutoLock,
  setAutoLockInterval,
  changeAutoLockItem,
  deleteAutoLockItem,
  addAutoLockItem,
  setAIGenerating,
} = slice.actions;

/// --- export reducer ---

export default slice.reducer;
