import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Loading from '../../Loading';
import SearchBox from '../../SearchBox';
import useSetFilterOptions from './useSetFilterOptions';

function SetFilterValues(props) {
  const {
    className,
    multiple = false,
    autoFocus = false,
    context,
    colDef,
    values: valuesProp,
    onChange,
  } = props;

  const values = useMemo(() => {
    return Array.isArray(valuesProp)
      ? valuesProp
      : []
          .concat(valuesProp)
          .filter((v) => v !== undefined && v !== null && v !== '')
          .map((v) => {
            // 兼容一下通过 json 配置了 set 的 values 直接就是 ['a', 'b'] 这种发给后端的情况
            if (typeof v !== 'object') {
              return { value: v, label: v };
            }
            return v;
          });
  }, [valuesProp]);

  const { t } = useTranslation(['icp-components', 'icp-vendor-aggrid']);

  const [searchText, setSearchText] = useState('');
  const allDataFetched = useRef(false);

  const { options, loading, moreLoading, hasMore, loadMore } = useSetFilterOptions({
    searchText: allDataFetched.current ? '' : searchText,
    context,
    colDef,
  });

  // 一次性 flag
  if (!searchText && !hasMore) {
    allDataFetched.current = true;
  }

  const optionsFiltered = useMemo(() => {
    if (!options) {
      return [];
    }
    if (allDataFetched.current) {
      // 没有 searchText 的时候还没有 hasMore，说明前端已有所有数据，此时改变 searchText 不需要再后端搜索。
      // 使用前端过滤
      return options.filter((op) => {
        return !searchText || op.label?.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return options;
  }, [options, searchText]);

  const isAllSelected = useMemo(() => {
    return (
      !hasMore &&
      values.length &&
      optionsFiltered.length &&
      !optionsFiltered.find((op) => !values.find((v) => op.value === v.value))
    );
  }, [hasMore, optionsFiltered, values]);

  const indeterminate = useMemo(() => {
    return (
      !isAllSelected &&
      values.length &&
      optionsFiltered.length &&
      (hasMore ||
        (optionsFiltered.find((op) => values.find((v) => op.value === v.value)) &&
          optionsFiltered.find((op) => !values.find((v) => op.value === v.value))))
    );
  }, [hasMore, isAllSelected, optionsFiltered, values]);

  const handleCheckAll = () => {
    let newValues;
    const hasUnselect = optionsFiltered.find((op) => !values.find((v) => op.value === v.value));
    if (hasUnselect) {
      const rest = optionsFiltered.filter((op) => !values.find((v) => op.value === v.value));
      newValues = values.concat(rest);
    } else {
      newValues = values.filter((v) => !optionsFiltered.find((op) => op.value === v.value));
    }

    // 一个都没有设置为 undefined 表示没有此过滤条件
    onChange(newValues.length ? newValues : undefined);
  };

  const handleChange = (v, oldChecked) => {
    if (multiple) {
      if (oldChecked) {
        const newValues = values.filter((item) => item.value !== v);
        // 一个都没有设置为 undefined 表示没有此过滤条件,
        // 否则从 ui 上看 Toolbar set 类型的过滤条件一个都没选，但是 table 没显示数据
        onChange(newValues.length ? newValues : undefined);
      } else {
        const option = optionsFiltered.find((op) => op.value === v);
        onChange(values.concat({ value: option.value, label: option.label }));
      }
      return;
    }

    if (oldChecked) {
      onChange(undefined);
    } else {
      const option = optionsFiltered.find((op) => op.value === v);
      onChange({ value: option.value, label: option.label });
    }
  };

  const handleScroll = (event) => {
    if (!hasMore || moreLoading || loading) {
      return;
    }
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - clientHeight < 32 * 2;
    if (isBottom) {
      loadMore();
    }
  };

  return (
    <div className={clsx('icp-filter-set-values', className)}>
      <SearchBox
        open={true}
        suppressSpeech={true}
        autoFocus={autoFocus}
        // debounce={false}
        onSearch={setSearchText}
      />
      {loading || !options ? (
        <Loading size={16} style={{ height: 80 }} />
      ) : (
        <div className="icp-table-menu icp-thin-scrollbar" onScroll={handleScroll}>
          {multiple ? (
            <div className="item icp-clickable" onClick={handleCheckAll}>
              <Checkbox
                checked={isAllSelected}
                indeterminate={indeterminate}
                style={{ pointerEvents: 'none' }}
              />
              {t('selectAll', { ns: 'icp-vendor-aggrid' })}
            </div>
          ) : null}
          {optionsFiltered.map((op) => {
            const CellRenderer = colDef.cellRenderer;
            const checked = values.find((v) => op.value === v.value);
            return (
              <div
                key={op.value}
                className="item icp-clickable"
                onClick={() => handleChange(op.value, checked)}
              >
                <Checkbox checked={checked} style={{ pointerEvents: 'none' }} />
                {/* TODO, 这里写死 ENUM_COLUMN， ENUM_COLUMN 这个 const 在 icp-form-renderer-react 里，无法 import */}
                {colDef.type === 'ENUM_COLUMN' ? (
                  <CellRenderer value={op.value} {...colDef.cellRendererParams} context={context} />
                ) : (
                  op.label
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

SetFilterValues.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  autoFocus: PropTypes.bool,
  context: PropTypes.shape({}),
  colDef: PropTypes.shape({
    filterParams: PropTypes.shape({}),
    type: PropTypes.string,
    cellRenderer: PropTypes.elementType,
    cellRendererParams: PropTypes.shape({}),
  }),
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
};

export default SetFilterValues;
