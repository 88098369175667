import './AgTable.css';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useEventCallback } from '@icp/hooks';
import { getResourceBundle } from '@icp/i18n';
import { composeEvent, debounce, getSearchParam, updateSearchParam } from '@icp/utils';
import { isEqual } from 'lodash-es';
import { AG_FILTER_FILTER_TYPES, AG_FILTER_TYPES, DEFAULT_COL_DEF } from '@icp/components-core';
import TableToolbar from './TableToolbar';
import {
  apiFilterModelToGrid,
  formatColumnDefs,
  getHasColumnCanFilter,
  getTableCurrentPageRowNodes,
  isClickOutDataArea,
  isFilterModelEqual,
  isStateEqual,
  loadTableInitState,
  transformRequestToApi,
} from './utils';
import AsyncAgGrid from './AsyncAgGrid';
import CustomDateComponent from './CustomDateComponent';
import PaginationStatusBar from './PaginationStatusBar';
import TotalRowsStatusBar from './TotalRowsStatusBar';
import CustomLoadingCellRenderer from './CustomLoadingCellRenderer';
import { clearSetting, saveSetting } from './settingHandler';
import CustomLoadingOverlay from './CustomLoadingOverlay';
import Loading from '../Loading';
import { filterInvalidFilterModel } from '../Toolbar';
import { toApiFilterModel } from '../Toolbar/FilterPanel/utils';

const EMPTY_ARRAY = [];

const AgTable = forwardRef(function AgTable(props, ref) {
  const { t } = useTranslation(['icp-components']);

  const {
    componentLibrary,
    id,
    className,
    style,
    isInDesign,
    columnTypes,
    defaultColDef: defaultColDefProp,
    autoGroupColumnDef: autoGroupColumnDefProp,
    columnDefs: columnDefsProp,
    rowData,
    rowModelType,
    getRows: getRowsProp,
    defaultFilterModel,
    defaultSearchText,
    bordered = false,
    suppressStatusbar = false,
    suppressStatusBarTotalRows = false,
    suppressToolbar = false,
    suppressToolbarActions = false,
    suppressAddButton = false,
    suppressDeleteButton = false,
    suppressFuzzySearch = false,
    suppressFuzzySearchSpeech = false,
    suppressColumnSelect = false,
    suppressExcelExport = false,
    suppressToolbarRefresh = false,
    suppressTableSetting = false,
    suppressFullscreen = false,
    suppressSaveSetting = false,
    supportShowDeleted = false,
    suppressFilterPanel = false,
    suppressFavoriteView = false,
    suppressRefreshDataWhenFilterChange = false,
    settingKey: settingKeyProp,
    tableSize: tableSizeProp = 'default',
    addButtonHref,
    addButtonContent,
    fuzzySearchPlaceholder = t('table.search-placeholder'),
    fuzzySearchOpen = false,
    pagination: paginationProp = false,
    paginationPageSize: paginationPageSizeProp = 30,
    pinnedFilter,
    containerProps,
    toolbarProps,
    toolbarChildren,
    rowSelection,
    onGridReady: onGridReadyProp,
    onToolbarExportExcel,
    onRefresh,
    onAddRow,
    onDeleteRow,
    context: contextProp,
    combinedView,
    ...other
  } = props;

  // use 会发请求获取 icp-vendor-aggrid.json，getResourceBundle 是从内存里拿
  useTranslation(['icp-vendor-aggrid']);
  const localeText = useMemo(() => getResourceBundle('icp-vendor-aggrid'), []);

  const settingKey = !isInDesign && !suppressSaveSetting ? settingKeyProp : null;
  const searchKey = id ? `${id}-search` : null;
  const defaultColDef = useMemo(() => {
    return {
      ...DEFAULT_COL_DEF,
      ...defaultColDefProp,
    };
  }, [defaultColDefProp]);
  const defaultTableState = useMemo(() => {
    return {
      tableSize: tableSizeProp,
      columnState: undefined,
      filterModel: Array.isArray(defaultFilterModel) ? defaultFilterModel : [],
      paginationPageSize: paginationPageSizeProp,
    };
    // 响应 default 值的变化其实没意义，这里只是为了支持在 designer 里设置实时显示而已
  }, [defaultFilterModel, paginationPageSizeProp, tableSizeProp]);
  // tableState 里的对象是会保存到 localstorage 的属性
  const [tableState, setTableState] = useState(() => {
    return loadTableInitState(settingKey, defaultTableState);
  });
  const { tableSize, columnState, filterModel, paginationPageSize } = tableState;
  const [searchText, setSearchText] = useState(
    defaultSearchText || getSearchParam(searchKey) || '',
  );
  const [selectionState, setSelectionState] = useState({
    hasSelect: false,
    allSelected: false,
    indeterminate: false,
  });
  const [hasDeletable, setHasDeletable] = useState(false);
  // gridApi 存到 state 里才能保证 useImperativeHandle 以及 TableToolbar 有 gridApi 刷新过后可用
  const [gridApi, setGirdApi] = useState(null);

  const containerRef = useRef(null);
  const toolbarRef = useRef(null);
  const readyToSaveSetting = useRef(false);
  // flag to suppress ag-grid update warning when is unmount
  const isUnMount = useRef(false);
  const [forceMount, setForceMount] = useState(Date.now());

  useEffect(() => {
    return () => {
      isUnMount.current = true;
    };
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (!gridApi) return;
      if (!gridApi.getEditingCells().length) return;

      if (isClickOutDataArea(event.target, containerRef.current)) {
        gridApi.stopEditing();
      }
    };

    // 不能用 click， 事件单独加在 document 上的和 react 是两套系统，如果用 click 的话会导致 onCellValueChanged 晚于 FormRenderer 的 submit 事件触发。
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [gridApi]);

  const { autoGroupColumnDef, columnDefs } = useMemo(() => {
    // 不能先传原始 columnDefs 给 ag-grid，然后在 grid ready 调用 applyColumnState
    // 这样的话会先渲染原始的列，再又一个过渡动画到改变的 columnState，
    // 所以在 format 方法里提前 merge 好保存的 columnState 进 columnDefs
    return formatColumnDefs({
      autoGroupColumnDefProp,
      columnDefsProp,
      defaultColDef,
      columnTypes,
      settingKey,
      columnState,
      treeData: other.treeData,
    });
    // tableSize 改变的时候 AsyncAgGrid 的 key 发生了改变，是一个全新的 ag-grid，需要重新计算 columnDefs
    // autoGroupColumnDef 就不响应 change 重新 format 了
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceMount, columnDefsProp, defaultColDef, columnTypes]);

  // group 功能和分页功能是冲突的
  const pagination = useMemo(() => {
    if (!paginationProp) {
      return false;
    }

    if (!columnState) {
      return !(columnDefs || []).find((item) => item.rowGroup);
    }

    return !(columnState || []).find((item) => item.rowGroup);
  }, [columnDefs, paginationProp, columnState]);

  const getRows = useEventCallback((params) => {
    const request = transformRequestToApi(params, filterModel, searchText);
    getRowsProp(request).then(
      (res) => {
        if (isUnMount.current) {
          return;
        }

        if (!res?.rowData) {
          params.fail();
          return;
        }

        params.success({ rowData: res.rowData, rowCount: res.rowCount });
        params.api.hideOverlay();
        if (res.rowCount === 0) {
          setTimeout(() => {
            params.api.showNoRowsOverlay();
          }, 16);
        }
      },
      () => {
        if (isUnMount.current) {
          return;
        }

        params.fail();
        params.api.hideOverlay();
      },
    );
  });

  // Set data source when grid ready,
  // ag-grid on grid ready event will only trigger once.
  // useEventCallback to promise getRows is newest, because of getRows depend on outerFilterModel, it
  // could be refreshed before handleGridReady called by ag-grid.
  const handleGridReady = useEventCallback((params) => {
    // 不在 serverSide 模式下将 filterMode 通过 gridApi.setFilterModel 设置给 ag-grid 是因为 server side
    // 下 values 函数异步调用 params.success 会碰到以下无法解决的问题：
    // 1. 当用户有存下来的此列 filter 的时候，ag-grid 再表格初始化过后就会立即调用 values 函数获取 filter 选项，没必要。
    // 2. ag-grid 会发两个请求，第一个是不应用此列 filter 的，第二个才是用了此列 filter 的。
    // 3. 当已经存了用户设置的 set filter 选项 ['a', 'b']，如果当 values 函数 返回的 filter options 里没有 ['b']，
    //    那 ag-grid 会再发送的请求中值发送 ['a'] 作为此列 filter。这无法实现 values 函数的懒加载，而当此列是 ACL
    //    的时候懒加载是需要的，例如如果此列是用户，可能用上万个用户，不可能一次性加载过多的数据仅仅只是为了获取 filter options
    // 4. 无法在 ag-grid 定义的 values 函数里实现类似 startRow endRow 的懒加载功能
    // 所以解决方案改为在 getRows 里单独拼接进 payload
    if (filterModel && rowModelType === 'clientSide') {
      params.api.setFilterModel(apiFilterModelToGrid(filterModel));
    }

    if (searchText && rowModelType === 'clientSide') {
      params.api.setGridOption('quickFilterText', searchText);
    }

    if (!isInDesign && rowModelType === 'serverSide' && getRowsProp) {
      params.api.setGridOption('serverSideDatasource', { getRows });
    }

    setGirdApi(params.api);
    onGridReadyProp?.(params);

    setTimeout(() => {
      // 防止 ag-grid 初始化的时候就调用很多次 columnResized 和 displayedColumnsChanged
      // 例如当 column 有 flex 1 的时候，ag-grid 会自动调整列触发 columnResized，这并不是我们想要保存 setting 的初衷：人为调整
      // 所以延迟一个比较长的时间
      readyToSaveSetting.current = true;
    }, 1000);
  });

  const handleKeyDown = (event) => {
    if (
      event.key === 'f' &&
      (event.metaKey || event.ctrlKey) &&
      !(event.metaKey && event.ctrlKey) &&
      toolbarRef.current?.openSearch
    ) {
      event.preventDefault();
      toolbarRef.current.openSearch();
    }
  };

  const handleToolbarExport = (params) => {
    if (onToolbarExportExcel) {
      onToolbarExportExcel(params);
      return;
    }

    if (rowModelType === 'clientSide') {
      gridApi?.exportDataAsExcel({
        columnKeys: gridApi
          .getAllGridColumns()
          .filter((column) => {
            const { colDef } = column;
            return (
              column.visible &&
              !(colDef.colId === undefined || colDef.colId === null) &&
              colDef.colId !== 'ag-Grid-ControlsColumn' &&
              colDef.colId !== 'ag-Grid-SelectionColumn' &&
              colDef.type !== 'ACTION_COLUMN'
            );
          })
          .map((colDef) => colDef.colId),
      });
    }
  };

  const handleFuzzySearch = (val) => {
    setSearchText(val);
    updateSearchParam(searchKey, val);

    if (!suppressRefreshDataWhenFilterChange) {
      if (rowModelType === 'clientSide') {
        gridApi?.setGridOption('quickFilterText', val);
      } else {
        gridApi?.refreshServerSide({ purge: true });
      }
    }
  };

  const handleRefresh = useEventCallback(() => {
    // 需要放到最上面，否则当调用gridApi.setFilterModel 过后 server-side 会返回 1（因为有一条 loading 行）
    const isNoData = gridApi.getDisplayedRowCount() === 0;

    // 处理有 suppressRefreshDataWhenFilterChange 导致的 filterModel 和 searchText 没有设置给 ag-grid 的问题
    if (suppressRefreshDataWhenFilterChange) {
      if (
        rowModelType === 'clientSide' &&
        !isFilterModelEqual(filterModel, gridApi.getFilterModel())
      ) {
        gridApi.setFilterModel(apiFilterModelToGrid(filterModel));
      }
      if (rowModelType === 'clientSide' && searchText !== gridApi.getQuickFilter()) {
        gridApi.setGridOption('quickFilterText', searchText);
      }
    }

    if (onRefresh) {
      gridApi.showLoadingOverlay();
      // callback func in clientSide
      Promise.resolve(onRefresh()).finally(() => gridApi.hideOverlay());
    } else if (rowModelType === 'serverSide') {
      if (isNoData) {
        // hide no rows overlay
        gridApi.hideOverlay();
      } else {
        // loading overlay will hide in getRows success
        gridApi.showLoadingOverlay();
      }
      gridApi.refreshServerSide({ purge: isNoData });
    }
  });

  const forceReloadTable = () => {
    readyToSaveSetting.current = false;
    setGirdApi(null);
    setForceMount(Date.now());
  };

  const handleTableStateChange = (obj, isReset) => {
    if (isInDesign) {
      // design 的时候不记录任何的 state，否则会导致 schema 在变动的时候表格列的状态没发生改变
      return;
    }

    const oldState = tableState;
    const newState = { ...oldState, ...obj };

    if (isReset) {
      clearSetting(settingKey);
    }

    if (isStateEqual(newState, oldState)) {
      return;
    }
    setTableState(newState);

    // 保存新的 table state
    if (!isReset && readyToSaveSetting.current) {
      saveSetting(settingKey, newState);
    }

    // 处理表格额外的刷新
    for (const [key, value] of Object.entries(obj)) {
      // filterModel 的改动是由 toolbar 触发，需要设置给 ag-grid
      if (key === 'filterModel') {
        const onlyFilterChanged = Object.keys(obj).length === 1;
        if (
          !(onlyFilterChanged && suppressRefreshDataWhenFilterChange) &&
          !isEqual(filterInvalidFilterModel(filterModel), filterInvalidFilterModel(value))
        ) {
          if (rowModelType === 'clientSide') {
            gridApi?.setFilterModel(apiFilterModelToGrid(value));
          } else {
            gridApi?.refreshServerSide({ purge: true });
          }
        }
      }
      if (key === 'tableSize' && newState.tableSize !== oldState.tableSize) {
        // ag-grid 需要重新加载才能响应 tableSize 的 css 变量变化
        forceReloadTable();
      }
    }
    // obj 有多个值表示是 handleSwitchFavoriteView 或者 handleResetSetting
    if (Object.keys(obj).length > 1) {
      if (newState.columnState) {
        gridApi?.applyColumnState({
          state: newState.columnState,
          applyOrder: true,
        });
      } else {
        // 切换到 默认 view 的时候一般没有 columnState，其实是需要调用 resetColumnState，但是因为我们在上面把
        // 最初从 localStorage 里读取到的 columnState merge 进了 columnDefs，导致 resetColumnState reset
        // 到的是 merge 过后的，也就是刚进入这个页面 localStorage 里保存的值，而不是期望的没有 columnState。所以
        // 需要重新计算 columnDefs，这里暴力调用一下 force reload，等 ag-grid 升级到 31 过后使用它提供的 initialState
        // 属性就可以不在这个组件里 merge columnState 进去 columnDefs，这里应该就可以直接调用 resetColumnState 了。
        forceReloadTable();
      }
    }
  };

  // 不能和 handleDisplayedColumnsChanged 进行合并，resize 事件会和 pinned 等事件一起发生，debounce 过后 resize 事件会覆盖掉其余 column 事件
  const handleColumnResized = debounce((params) => {
    if (!readyToSaveSetting.current) {
      return;
    }
    const newColumnState = params.api.getColumnState();
    handleTableStateChange({ columnState: newColumnState });
  });

  const handleGridColumnsChanged = debounce((params) => {
    if (!readyToSaveSetting.current) {
      return;
    }

    // 处理动态通过 api 修改了 columnDefs，已经存下来的 filterModel 里有新增的 columnDefs 的列，此时 Toolbar 会显示存下来的
    // filter 设置，但是 table 本身并没有应用上此 filter，需要重新设置一遍否则看到的数据是不对的。
    if (
      rowModelType === 'clientSide' &&
      !isFilterModelEqual(filterModel, params.api.getFilterModel())
    ) {
      params.api.setFilterModel(apiFilterModelToGrid(filterModel));
    }
  });

  // This can result from columns open / close, column move, pin, pivot, group, etc
  const handleDisplayedColumnsChanged = debounce((params) => {
    // 因为有 debounce 可能在 unmount 只有调用，所以需要判断是否被 destroy 了
    if (params.api.isDestroyed()) {
      return;
    }
    if (!readyToSaveSetting.current) {
      return;
    }
    const newColumnState = params.api.getColumnState();
    handleTableStateChange({ columnState: newColumnState });
  });

  const handlePaginationChange = debounce((params) => {
    if (!pagination) {
      return;
    }

    const newPageSize = params.api.paginationGetPageSize();
    handleTableStateChange({ paginationPageSize: newPageSize });

    // 切换 page 还保留这 selection 不符合习惯操作，所以清空。
    if (selectionState.hasSelect) {
      gridApi.deselectAll();
    }
  });

  // 自定义 ServerSideCheckboxHeader 触发的 selectAll，ag-grid 并没有选择所有的 callback
  const handleSelectAll = useEventCallback((selected) => {
    setSelectionState({ hasSelect: selected, allSelected: selected, indeterminate: false });
    // Update header checkbox
    gridApi.refreshHeader();
    const currentPageRowNodes = getTableCurrentPageRowNodes(gridApi);
    // currentPageRowNodes.forEach((node) => node.setSelected(selected));
    gridApi.setNodesSelected({ nodes: currentPageRowNodes, newValue: true });
  });

  // handleSelectAll 里 forEach 会每个 rowNode 触发一次，故 debounce
  const handleSelectionChanged = debounce((params) => {
    if (params.api.isDestroyed()) {
      return;
    }

    if (rowModelType === 'serverSide' && params.source === 'uiSelectAll') {
      const state = params.api.getServerSideSelectionState();
      /// TODO
      if (state.selectAll) {
        handleSelectAll(true);
      }
      // params.api.selectAllOnCurrentPage()
    }

    if (rowModelType === 'serverSide' && pagination) {
      // selectionState 是给自定义的 headerCheckbox 用的，只有在 serverSide 并且开启 pagination 的时候才生效
      const currentPageRowNodes = getTableCurrentPageRowNodes(gridApi);
      const selectedRows = currentPageRowNodes.filter((node) => node.selected);
      const hasSelect = selectedRows.length > 0;
      const allSelected = hasSelect && selectedRows.length === currentPageRowNodes.length;
      const indeterminate = hasSelect && !allSelected;
      const newSelectionState = { hasSelect, allSelected, indeterminate };
      if (!isEqual(selectionState, newSelectionState)) {
        setSelectionState(newSelectionState);
        // Update header checkbox
        gridApi.refreshHeader();
      }
      setHasDeletable(hasSelect);
    } else if (rowModelType === 'clientSide') {
      setHasDeletable(
        params.api.getSelectedRows().filter((row) => row.deletable !== false).length > 0,
      );
    }
  }, 50);

  const handleResetSetting = () => {
    handleTableStateChange(defaultTableState, true);
  };

  const handleSwitchFavoriteView = (view) => {
    handleTableStateChange(view || defaultTableState, !view);
  };

  const getRowClass = (params) => {
    const defineClasses = [other.getRowClass ? other.getRowClass(params) : ''];
    if (params.data?.deleted) {
      defineClasses.push('icp-table-row-disabled');
    }
    return defineClasses.join(' ');
  };

  const components = useMemo(() => {
    return { ...other.components, agDateInput: CustomDateComponent };
  }, [other.components]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        !suppressStatusBarTotalRows
          ? {
              statusPanel: TotalRowsStatusBar,
              align: 'left',
            }
          : null,
        pagination && {
          statusPanel: PaginationStatusBar,
          align: 'right',
        },
      ].filter(Boolean),
    };
  }, [pagination, suppressStatusBarTotalRows]);

  const context = useMemo(() => {
    return {
      ...contextProp,
      id,
      settingKey,
      tableSize,
      selectionState,
      selectAll: handleSelectAll,
    };
  }, [contextProp, id, settingKey, tableSize, selectionState, handleSelectAll]);

  const hasColumnCanFilter = useMemo(() => {
    return getHasColumnCanFilter({ columnDefs, defaultColDef, columnTypes });
  }, [columnDefs, defaultColDef, columnTypes]);

  // 可能是 ag-grid 结合异步加载 AsyncAgGrid 打包有问题，导致父组件的 rowData 快速从 null 变成 [] 的时候
  // ag-grid 默认的 loading 去不掉，然后一直处于 loading 的状态。测试下来无法通过 ag-grid 提供的 api 解决，
  // 只能通过这里自己控制 loading 的显示，不使用 ag-grid 自动的判断 rowData 是 undefined 和 null 的时候显示 loading 的功能。
  const shouldShowClientSideLoading =
    rowModelType === 'clientSide' && (rowData === null || rowData === undefined);

  useImperativeHandle(
    ref,
    () => {
      return {
        node: containerRef.current,
        api: gridApi,
        getTableState: () => {
          const colDefs = gridApi.getAllGridColumns().map((col) => col.getColDef());
          return {
            ...tableState,
            filterModel: toApiFilterModel(tableState.filterModel, colDefs),
          };
        },
        getSearchText: () => searchText,
        refresh: handleRefresh,
      };
    },
    [gridApi, handleRefresh, searchText, tableState],
  );

  return (
    <div
      {...containerProps}
      id={id}
      className={clsx(
        'icp-ag-table',
        'ag-theme-quartz',
        {
          bordered,
          small: tableSize === 'small',
          large: tableSize === 'large',
          'show-client-side-loading': shouldShowClientSideLoading,
          'no-toolbar': suppressToolbar,
        },
        className,
        containerProps?.className,
      )}
      style={{
        ...style,
        ...containerProps?.style,
      }}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      ref={containerRef}
    >
      {!suppressToolbar ? (
        <TableToolbar
          {...toolbarProps}
          isInDesign={isInDesign}
          componentLibrary={componentLibrary}
          gridApi={gridApi}
          columnDefs={columnDefs}
          suppressToolbarActions={suppressToolbarActions}
          suppressAddButton={suppressAddButton}
          suppressDeleteButton={suppressDeleteButton}
          suppressFuzzySearch={suppressFuzzySearch}
          suppressFuzzySearchSpeech={suppressFuzzySearchSpeech}
          suppressColumnSelect={suppressColumnSelect}
          suppressExcelExport={suppressExcelExport}
          suppressToolbarRefresh={suppressToolbarRefresh}
          suppressTableSetting={suppressTableSetting}
          suppressFullscreen={suppressFullscreen}
          supportShowDeleted={supportShowDeleted}
          suppressFilterPanel={!hasColumnCanFilter || suppressFilterPanel}
          suppressFavoriteView={suppressSaveSetting || suppressFavoriteView}
          addButtonHref={addButtonHref}
          addButtonContent={addButtonContent}
          fuzzySearchPlaceholder={fuzzySearchPlaceholder}
          fuzzySearchOpen={fuzzySearchOpen}
          rowSelection={rowSelection}
          hasDeletable={hasDeletable}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          onExportExcel={handleToolbarExport}
          searchText={searchText}
          onSearch={handleFuzzySearch}
          onRefresh={handleRefresh}
          tableState={tableState}
          onTableStateChange={handleTableStateChange}
          pinnedFilter={pinnedFilter}
          onResetSetting={handleResetSetting}
          settingKey={settingKey}
          onSwitchFavoriteView={handleSwitchFavoriteView}
          context={context}
          combinedView={combinedView}
          ref={toolbarRef}
        >
          {toolbarChildren}
        </TableToolbar>
      ) : null}
      <AsyncAgGrid
        // 使用 key 来实现当全新加载 ag-grid
        key={forceMount}
        suppressGroupChangesColumnVisibility={true}
        suppressDragLeaveHidesColumns={true}
        suppressPaginationPanel={true}
        suppressMultiSort={true}
        suppressContextMenu={true}
        animateRows={true}
        enableBrowserTooltips={true}
        enableCellTextSelection={true}
        serverSideSortAllLevels={rowModelType === 'serverSide' ? true : undefined}
        statusBar={!suppressStatusbar && statusBar.statusPanels.length ? statusBar : null}
        rowSelection={
          rowSelection
            ? {
                mode:
                  (rowSelection === 'single' && 'singleRow') ||
                  (rowSelection === 'multiple' && 'multiRow') ||
                  rowSelection?.mode,
                selectAll: rowModelType === 'clientSide' ? 'filtered' : 'all',
                hideDisabledCheckboxes: true,
                enableClickSelection: true,
                isRowSelectable: (rowNode) => rowNode.data?.selectable !== false,
                ...(rowSelection && typeof rowSelection === 'object' ? rowSelection : {}),
              }
            : undefined
        }
        selectionColumnDef={{
          width: 32,
          minWidth: 32,
        }}
        {...other}
        context={context}
        loadingCellRenderer={CustomLoadingCellRenderer}
        loadingOverlayComponent={CustomLoadingOverlay}
        components={components}
        getRowClass={getRowClass}
        localeText={localeText}
        // formatColumnDefs 已经 merge 过 columnTypes 和 defaultColDef 进 columnDefs 了，为了 Toolbar
        // 方便的识别 filter 等属性。
        // 这里依然传给 ag-grid，否则 console 会报 waning。ag-grid 会再 merge 一遍 columnTypes 进 colDef
        columnTypes={columnTypes}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        rowData={
          (shouldShowClientSideLoading && EMPTY_ARRAY) ||
          (rowModelType === 'clientSide' && rowData) ||
          undefined
        }
        rowModelType={rowModelType}
        pagination={pagination}
        paginationPageSize={pagination ? paginationPageSize : undefined}
        cacheBlockSize={pagination ? paginationPageSize : other.cacheBlockSize}
        onGridReady={handleGridReady}
        onColumnResized={composeEvent(handleColumnResized, props.onColumnResized)}
        onGridColumnsChanged={composeEvent(handleGridColumnsChanged, props.onGridColumnsChanged)}
        onDisplayedColumnsChanged={composeEvent(
          handleDisplayedColumnsChanged,
          props.onDisplayedColumnsChanged,
        )}
        onSortChanged={composeEvent(handleDisplayedColumnsChanged, props.onSortChanged)}
        onSelectionChanged={composeEvent(handleSelectionChanged, props.onSelectionChanged)}
        onPaginationChanged={composeEvent(handlePaginationChange, props.onPaginationChanged)}
      />
      {shouldShowClientSideLoading ? <Loading delayed={false} overlay={true} /> : null}
    </div>
  );
});

AgTable.propTypes = {
  componentLibrary: PropTypes.oneOf(['material-ui', 'ant-design']),
  id: PropTypes.string,
  className: PropTypes.string,
  isInDesign: PropTypes.bool,
  rowModelType: PropTypes.oneOf(['serverSide', 'clientSide']),
  autoGroupColumnDef: PropTypes.shape({}),
  columnTypes: PropTypes.shape({}),
  defaultColDef: PropTypes.shape({}),
  columnDefs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      // all other ag-grid column def properties
    }),
  ),
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  getRows: PropTypes.func,
  defaultFilterModel: PropTypes.arrayOf(
    PropTypes.shape({
      colId: PropTypes.string,
      filterType: PropTypes.oneOf(AG_FILTER_FILTER_TYPES),
      type: PropTypes.oneOf(AG_FILTER_TYPES),
      filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      filterTo: PropTypes.number,
      dateFrom: PropTypes.string,
      dateTo: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      ),
    }),
  ),
  defaultSearchText: PropTypes.string,
  bordered: PropTypes.bool,
  suppressStatusbar: PropTypes.bool,
  suppressStatusBarTotalRows: PropTypes.bool,
  suppressToolbar: PropTypes.bool,
  suppressToolbarActions: PropTypes.bool,
  suppressAddButton: PropTypes.bool,
  suppressDeleteButton: PropTypes.bool,
  suppressFuzzySearch: PropTypes.bool,
  suppressFuzzySearchSpeech: PropTypes.bool,
  suppressColumnSelect: PropTypes.bool,
  suppressExcelExport: PropTypes.bool,
  suppressToolbarRefresh: PropTypes.bool,
  suppressTableSetting: PropTypes.bool,
  suppressFullscreen: PropTypes.bool,
  suppressSaveSetting: PropTypes.bool,
  supportShowDeleted: PropTypes.bool,
  suppressFilterPanel: PropTypes.bool,
  suppressFavoriteView: PropTypes.bool,
  suppressRefreshDataWhenFilterChange: PropTypes.bool,
  settingKey: PropTypes.string,
  tableSize: PropTypes.oneOf(['default', 'small']),
  addButtonHref: PropTypes.string,
  addButtonContent: PropTypes.string,
  fuzzySearchPlaceholder: PropTypes.string,
  fuzzySearchOpen: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  rowSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  containerProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
  }),
  toolbarProps: PropTypes.shape({}),
  toolbarChildren: PropTypes.node,
  onGridReady: PropTypes.func,
  onColumnResized: PropTypes.func,
  onDisplayedColumnsChanged: PropTypes.func,
  onFilterChanged: PropTypes.func,
  onSortChanged: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onPaginationChanged: PropTypes.func,
  onGridColumnsChanged: PropTypes.func,
  onToolbarExportExcel: PropTypes.func,
  onRefresh: PropTypes.func,
  onAddRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  context: PropTypes.shape({}),
  combinedView: PropTypes.shape({}),
  // all other ag-grid grid options
};

// for Designable.js
AgTable.displayName = 'AgTable';

export default AgTable;
