import { parseJSON } from '@icp/utils';
import { makeNewFormSchema } from './makeNew';
import { ensureFields } from './formEntityHelper';

export const Converter = {
  FormEntity: {
    fromDTO: (data) => {
      if (!data) return data;

      const layouts = (data.layouts || []).map(({ schemaJson, ...layout }) => ({
        ...layout,
        schema: parseJSON(schemaJson) ?? makeNewFormSchema(),
      }));

      const { autoLockJson, dataSecurityJson, ...other } = data;

      return {
        ...other,
        isInnerEntity: !!+data.isInnerEntity,
        isMasterData: !!+data.isMasterData,
        isDeletableWhenReferenced: data.isDeletableWhenReferenced !== 0,
        layouts,
        isAutoLock: !!+data.isAutoLock,
        autoLock: parseJSON(autoLockJson),
        dataSecurity: parseJSON(dataSecurityJson),
      };
    },
    toDTO: (data) => {
      if (!data) return data;

      data = JSON.parse(JSON.stringify(data));

      const layouts = (data.layouts || []).map(({ schema, ...layout }, index) => ({
        ...layout,
        sortIndex: index,
        schemaJson: JSON.stringify(schema),
      }));

      const { autoLock, dataSecurity, ...other } = data;
      return {
        ...other,
        isInnerEntity: data.isInnerEntity ? 1 : 0,
        isMasterData: data.isMasterData ? 1 : 0, // ?? 没看懂为什么 api 是 0 和 1
        isDeletableWhenReferenced: data.isDeletableWhenReferenced === false ? 0 : 1,
        layouts,
        fields: ensureFields(data.fields, data.layouts, data.pbcToken),
        isAutoLock: data.isAutoLock ? 1 : 0,
        autoLockJson: autoLock != null ? JSON.stringify(autoLock) : undefined,
        dataSecurityJson: dataSecurity != null ? JSON.stringify(dataSecurity) : undefined,
      };
    },
  },
  PageLayout: {
    fromDTO: (data) => {
      if (!data) return data;

      const { schemaJson, ...other } = data;

      return {
        ...other,
        schema: parseJSON(schemaJson),
      };
    },
    toDTO: (data) => {
      if (!data) return data;

      const { schema, ...other } = data;

      return {
        ...other,
        schemaJson: JSON.stringify(schema),
      };
    },
  },
};
