import PropTypes from 'prop-types';
import {
  AG_FILTER_FILTER_TYPES,
  AG_FILTER_TYPES,
  AG_FILTER_TYPES_NUMBER,
  AG_SORT_TYPES,
} from '@icp/components';
import { PREDICATE_CONDITIONS, DEPRECATED_CONDITIONS } from '@icp/form-renderer-core';

export const PermissionPredicatePropType = PropTypes.shape({
  /**
   * will be used to check if the `context.userPermissionMap` contains all the specified permission token list.
   */
  hasAllOf: PropTypes.arrayOf(PropTypes.string),
  /**
   * will be used to check if the `context.userPermissionMap` contains any of the specified permission token list.
   */
  hasAnyOf: PropTypes.arrayOf(PropTypes.string),
});

export const DataPredicatePropType = PropTypes.shape({
  /**
   * `conditions` 是要全部满足还是只满足其中一个
   */
  operator: PropTypes.oneOf(['AND', 'OR']),
  /**
   * 匹配当前数据需要满足的条件列表，由 `operator` 来决定是全部满足还是只满足一个
   */
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * 数据字段的 id
       */
      dataField: PropTypes.string,
      /**
       * 数据字段的 id，可以写 `variable pattern`
       */
      field: PropTypes.string,
      /**
       * 条件
       */
      condition: PropTypes.oneOf(DEPRECATED_CONDITIONS.concat(PREDICATE_CONDITIONS)),
      /**
       * 值
       */
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      /**
       * 同 DataPredicatePropType
       */
      operator: PropTypes.oneOf(['AND', 'OR']),
      /**
       * 同 DataPredicatePropType
       */
      conditions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
});

const PredicateShape = (rawPropType) =>
  PropTypes.shape({
    /**
     * 需要满足的权限条件，如果是 undefined 则表示不验证权限
     */
    permissionPredicate: PermissionPredicatePropType,
    /**
     * 需要满足的数据条件，如果是 undefined 则表示不验证数据
     */
    dataPredicate: DataPredicatePropType,
    /**
     * 当条件满足时的值
     * @default true
     */
    valueIfPositive: rawPropType,
    /**
     * 当条件不满足的值
     * @default false
     */
    valueIfNegative: rawPropType,
  });

const MatchableShape = (rawPropType) =>
  PropTypes.shape({
    /**
     * 当 `matched` 匹配值为 `true` 的时候返回的值
     */
    value: rawPropType,
    /**
     * 当 `matched` 返回的值是 `true` 的时候才应用这个值
     */
    matched: PredicateShape(PropTypes.bool),
  });

export const ConditionalListPropertyPropType = (elementPropType) =>
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      elementPropType,
      PredicateShape(elementPropType),
      MatchableShape(elementPropType),
    ]),
  );

export const ConditionalPropertyPropType = (rawPropType) =>
  PropTypes.oneOfType([
    rawPropType,
    PredicateShape(rawPropType),
    ConditionalListPropertyPropType(rawPropType),
  ]);

export const ConditionalListOrSinglePropertyPropType = ConditionalPropertyPropType;

export const DataFiltersType = PropTypes.oneOfType([
  PropTypes.string,
  ConditionalListPropertyPropType(
    PropTypes.shape({
      /**
       * 字段 id。
       */
      id: PropTypes.string,
      /**
       * 过滤器的类型。
       */
      filterType: PropTypes.oneOf(AG_FILTER_FILTER_TYPES),
      /**
       * filter 的类型，兼容 ag-grid 大部分的 column filter 类型。
       */
      type: PropTypes.oneOf(AG_FILTER_TYPES),
      /**
       * 字段值，老配置，改成了 filter。
       * @deprecated
       */
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * 字段值
       * 当 filterType 是 number 并且 filter 是 inRange 的时候，表示 number 的下限。
       */
      filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * 当 filterType 是 number，并且 filter 是 inRange 的时候此值有效，表示 number 的上限。
       */
      filterTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * 当 filterType 是 date，并且 filter 不是 blank 和 notBlank 的时候此值有效，表示选择的日期（或者下限）。
       */
      dataFrom: PropTypes.string,
      /**
       * 当 filterType 是 date，并且 filter 是 inRange 的时候此值有效，表示选择日期的上限。
       */
      dateTo: PropTypes.string,
      /**
       * filterType 是 `set` 时传入的集合值。
       */
      values: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      ),
      /**
       * 同 DefinDataFiltersType
       */
      conditions: PropTypes.arrayOf(PropTypes.shape({})),
      /**
       * 同 DefinDataFiltersType
       */
      operator: PropTypes.oneOf(['AND', 'OR']),
    }),
  ),
]);

export const DataFilterAfterAggregationType = PropTypes.arrayOf(
  PropTypes.shape({
    /**
     * 字段 id (聚合时配的字段alias别名)
     */
    id: PropTypes.string,
    /**
     * 字段值
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * filter 的类型
     */
    type: PropTypes.oneOf(['top', ...AG_FILTER_TYPES_NUMBER]),
  }),
);

export const SingleDataSourceTypes = {
  /**
   * FormEntity 作为数据源。
   */
  dataSource: PropTypes.shape({
    /**
     * FormEntity token.
     */
    token: PropTypes.string,
    /**
     * PBC token.
     */
    pbcToken: PropTypes.string,
    /**
     * 获取列表详细数据的地址。
     */
    listUrl: PropTypes.string,
    // 下面两个只有 ACLElement 用到
    /**
     * 获取列表所有数据 id 的地址。
     */
    listIdsUrl: PropTypes.string,
    /**
     * 根据 id 获取详细数据的地址。
     */
    listByIdsUrl: PropTypes.string,
  }),
  /**
   * 通过 `dataUrl` 获取数据。
   */
  dataUrl: ConditionalListOrSinglePropertyPropType(PropTypes.string),
  /**
   * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变。
   */
  dataFilters: DataFiltersType,
  /**
   * api 后端排序。
   * TODO, 暂时还是前端排序
   */
  sortModel: PropTypes.shape({
    id: PropTypes.string,
    sort: PropTypes.oneOf(AG_SORT_TYPES),
    type: PropTypes.oneOf(['text', 'number', 'date']),
  }),
  /**
   * 取 response data 里的某一个子数据来作为数据源。
   */
  dataResponseKeyPath: PropTypes.string,
  /**
   * 转换取到的数据，使用 eval 表达式，this 指向获取到的数据。
   */
  transformDataResponse: PropTypes.string,
  /**
   * 是否需要翻译请求结果。
   * @default false
   */
  translateDataResponse: PropTypes.bool,
  /**
   * 请求数据的 debounce time，会触发 leading。
   * @default 200
   */
  debounceTime: PropTypes.number,
  /**
   * 数据源的默认数据，会和 api 请求到的数据进行 merge。
   * 如果 api 请求返回的是数组，则和数组的每一项进行 merge。
   * 如果 api 请求返回的是个对象，则和此对象进行 merge。
   */
  defaultValue: PropTypes.shape({}),
  /**
   * 数据请求的http方法。
   * @default 'get'
   */
  httpMethod: PropTypes.oneOf(['get', 'post']),
  /**
   * 加载的数据字段，不配置则加载所有字段。
   */
  selectColId: PropTypes.arrayOf(PropTypes.string),
};

export const MultiDataSourceTypes = {
  /**
   * 多数据源，并发请求。
   */
  multiDataSource: PropTypes.arrayOf(PropTypes.shape(SingleDataSourceTypes)),
  /**
   * If true, 将多数据与中每个数据源的 data flatten 成一个数组。
   */
  multiDataSourceFlat: PropTypes.bool,
  /**
   * 有 multiDataSource 并且 multiDataSourceFlat true 的时候则前端排序。
   * 此配置与单数据源共用。此操作在 flat 之后进行。
   */
  sortModel: PropTypes.shape({
    id: PropTypes.string,
    sort: PropTypes.oneOf(AG_SORT_TYPES),
    type: PropTypes.oneOf(['text', 'number', 'date']),
  }),
  /**
   * 转换取到的数据，使用 eval 表达式，this 指向获取到的数据。此操作在 flat 之后进行。
   * 此配置与单数据源共用。
   */
  transformDataResponse: PropTypes.string,
  /**
   * 请求数据的 debounce time，会触发 leading。
   * 此配置与单数据源共用。
   * @default 200
   */
  debounceTime: PropTypes.number,
};

export const PropTypeLoadRemoteContext = PropTypes.arrayOf(
  PropTypes.shape({
    /**
     * 从此地址加载
     */
    url: PropTypes.string,
    /**
     * 提取响应结果
     */
    dataResponseKeyPath: PropTypes.string,
    /**
     * 转换获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 是否需要翻译请求结果
     * @default false
     */
    translateDataResponse: PropTypes.bool,
    /**
     * 作为context的某属性
     */
    alias: PropTypes.string,
    /**
     * 后续串行请求, 结构相同
     */
    subsequence: PropTypes.arrayOf(
      PropTypes.shape({
        /* url, alias, subsequence */
      }),
    ),
  }),
);
