import { useEffect, useRef, useState } from 'react';
import useEditableTableAsyncValue from './useEditableTableAsyncValue';

export default function useDisplayedRowData({
  refresh,
  id,
  dataUrlProp,
  defaultValueDataUrlProp,
  dataFilters,
  dataResponseKeyPath,
  transformDataResponse,
  transformDefaultValueResponse,
  orderField,

  hasParentTable,
  parentSelectedId,
  value,
  relationKey,
  currentRowDataRef,
}) {
  // 根据配置异步获取数据，获取完了自动设置到 store 里刷新组件的 props.value
  const { fetching } = useEditableTableAsyncValue({
    refresh,
    id,
    dataUrlProp,
    defaultValueDataUrlProp,
    dataFilters,
    dataResponseKeyPath,
    transformDataResponse,
    transformDefaultValueResponse,
    orderField,
  });

  // null to display loading
  const [rowData, setRowData] = useState(fetching ? null : []);
  const noNeedTriggerChange = useRef(false);

  useEffect(() => {
    // rowData change 会触发 onRowDataUpdated，这里延迟锁一下不触发
    noNeedTriggerChange.current = true;

    setTimeout(() => {
      noNeedTriggerChange.current = false;
    }, 16);

    if (hasParentTable) {
      if (!parentSelectedId) {
        setRowData([]);
      } else if (Array.isArray(value) && value !== currentRowDataRef.current) {
        const filteredData = value.filter((item) => {
          return String(item[relationKey]) === String(parentSelectedId);
        });
        // 可编辑表格会 mutable 直接修改 rowData 不能直接用 redux 的数据
        setRowData(JSON.parse(JSON.stringify(filteredData)));
      }
      return;
    }

    if (Array.isArray(value) && value !== currentRowDataRef.current) {
      // 可编辑表格会 mutable 直接修改 rowData 不能直接用 redux 的数据
      setRowData(JSON.parse(JSON.stringify(value)));
    }
  }, [value, parentSelectedId, hasParentTable, relationKey, currentRowDataRef]);

  return { rowData, noNeedTriggerChange };
}
