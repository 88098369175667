/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { getResourceBundle, getTFunc } from '@icp/i18n';
import { isValidElement } from 'react';
import dayjs from 'dayjs';
import { createRoot } from 'react-dom/client';
import { debounce } from '@icp/utils';
import { createDataProcessor, setAllGanttColumnState } from './utils';

function getZoomConfig(gantt) {
  return {
    levels: [
      {
        name: 'day',
        scale_height: 50,
        min_column_width: 50,
        scales: [
          { unit: 'day', step: 1, format: '%F %d, %Y' },
          { unit: 'hour', step: 1, format: '%H:00' },
        ],
      },
      {
        name: 'week',
        scale_height: 50,
        min_column_width: 60,
        scales: [
          {
            unit: 'week',
            step: 1,
            format: (date) => {
              const toDayStr = gantt.date.date_to_str('%d');
              const toMonthStr = gantt.date.date_to_str('%F');
              const toYearStr = gantt.date.date_to_str('%Y');
              const endDate = gantt.date.add(date, 7 - date.getDay(), 'day');
              const startMonth = toMonthStr(date);
              const endMonth = toMonthStr(endDate);
              const startYear = toYearStr(date);
              const endYear = toYearStr(endDate);

              const startStr = `${toDayStr(date)}${startMonth === endMonth ? '' : ` ${startMonth}`}${startYear === endYear ? '' : `, ${startYear}`}`;
              const endStr = `${toDayStr(endDate)} ${endMonth}, ${toYearStr(date)}`;
              return `${startStr} - ${endStr}`;
            },
          },
          { unit: 'day', step: 1, format: '%D %j' },
        ],
      },
      {
        name: 'month',
        scale_height: 50,
        min_column_width: 40,
        scales: [
          { unit: 'month', step: 1, format: '%F, %Y' },
          { unit: 'day', step: 1, format: '%d' },
        ],
      },
      {
        name: 'quarter',
        height: 50,
        min_column_width: 90,
        scales: [
          {
            unit: 'quarter',
            step: 1,
            format: (date) => {
              const month = gantt.date.date_to_str('%m')(date);
              const year = gantt.date.date_to_str('%Y')(date);
              return `Q${Math.floor((month - 1) / 3) + 1}, ${year}`;
            },
          },
          { unit: 'month', step: 1, format: '%M' },
        ],
      },
      {
        name: 'year',
        scale_height: 50,
        min_column_width: 50,
        scales: [
          { unit: 'year', step: 1, format: '%Y' },
          { unit: 'month', step: 1, format: '%M' },
        ],
      },
    ],
  };
}

export default function setGanttConfig({
  isInDesign,
  gantt,
  columnDefs,
  openDialog,
  modal,
  ganttConfig,
  dataSources,
  mapping,
  relation,
  deletePromisesRef,
  context,
  readonly,
  ganttStateRef,
  onGanttStateChange,
  isUnMount,
  isTreeDataSource,
  dateOptions,
}) {
  const t = getTFunc(['icp-common']);
  const localeObj = getResourceBundle('icp-vendor-dhtmlx-gantt');

  // 注册 plugins 必须放前面不然 auto_scheduling 会失效
  gantt.plugins({
    marker: true,
    critical_path: true,
    auto_scheduling: true,
    export_api: true,
  });

  if (localeObj) {
    gantt.i18n.setLocale(localeObj);
  }

  // 要在 setAllGanttColumnState 之前设置，因为该函数里面会修改 zoomLevel
  gantt.ext.zoom.init(getZoomConfig(gantt));

  // gantt.config.date_format="%Y-%m-%d %T %H:%i";
  gantt.config.readonly = readonly;
  gantt.config.grid_width = ganttStateRef.current.grid_width;
  gantt.config.work_time = true;
  // gantt.config.duration_unit = "hour";
  gantt.config.link_line_width = 1.5;
  gantt.config.drag_links = !!dataSources.link;
  gantt.config.drag_progress = !!mapping.progress;
  gantt.config.scale_height = 33;
  gantt.config.row_height = 33;
  gantt.config.bar_height = 22;
  gantt.config.initial_scroll = false;
  // gantt.config.sort = true;
  gantt.config.open_tree_initially = !isTreeDataSource;
  gantt.config.auto_scheduling = true;
  gantt.config.auto_scheduling_initial = false;
  gantt.config.auto_scheduling_strict = true;
  gantt.config.auto_scheduling_compatibility = true;
  gantt.config.external_render = {
    isElement: (element) => {
      return isValidElement(element);
    },
    renderElement: (element, container) => {
      const root = createRoot(container);
      root.render(element);
    },
  };

  Object.assign(gantt.config, ganttConfig);

  setAllGanttColumnState({ gantt, columnDefs, ganttState: ganttStateRef.current });

  if (isInDesign) {
    gantt.config.sort = false;
  }

  if (!isInDesign && !readonly) {
    gantt.createDataProcessor(
      createDataProcessor({
        gantt,
        dataSources,
        context,
        relation,
        dateOptions,
        deletePromisesRef,
      }),
    );
  }

  gantt.templates = {
    parse_date: (date) => {
      return dayjs(date).toDate();
    },
    format_date: (date) => {
      return date.toISOString();
    },
    scale_row_class: (scale) => {
      if (scale.unit === 'hour') {
        return 'hour-scale-row';
      }
      return undefined;
    },
    timeline_cell_class: (task, date) => {
      if (
        (gantt.getScale().unit === 'day' || gantt.getScale().unit === 'week') &&
        !gantt.isWorkTime({ date, task })
      ) {
        return 'no-work-time-cell';
      }
      return undefined;
    },
    rightside_text: (start, end, task) => {
      if (gantt.getTaskType(task) === gantt.config.types.milestone) {
        return task.text;
      }
      return '';
    },
  };

  gantt.showLightbox = (p) => {
    const task = gantt.getTask(p);
    openDialog(task);
  };
  gantt.hideLightbox = () => {};
  gantt.confirm = (p) => {
    modal.confirm({
      centered: true,
      title: p.title ?? t('confirm', { ns: 'icp-common' }),
      content: (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: p.content || p.text,
          }}
        />
      ),
      onOk: () => {
        p.callback(true);
      },
    });
  };

  gantt.attachEvent('onColumnResizeEnd', (index, column, new_width) => {
    const newWidths = gantt.getGridColumns().map((col) => {
      return {
        colId: col.name,
        width: col.name === column.name ? new_width : col.width,
      };
    });
    onGanttStateChange({
      columnSizing: {
        columnSizingModel: newWidths,
      },
    });
    return true;
  });

  // TODO, 有个小 bug，拖拽 grid 使得左侧所有列都变大过后，实际上并没有触发 onColumnResizeEnd，好像刷新过后 grid_width 最大还是按照所有 column 的 width 加和显示的。
  gantt.attachEvent('onGridResizeEnd', (old_width, new_width) => {
    onGanttStateChange({ grid_width: new_width });
    return true;
  });

  const updateScroll = debounce((left) => {
    onGanttStateChange({ scrollLeft: left });
  });
  gantt.attachEvent('onGanttScroll', (left) => {
    updateScroll(left);
  });

  gantt.attachEvent('onDataRender', () => {
    if (ganttStateRef.current.scrollLeft !== undefined) {
      // 异步一下，否则设置不上去，应该是 gantt 组件内部有什么异步操作
      setTimeout(() => {
        if (isUnMount.current) {
          return;
        }
        gantt.scrollTo(ganttStateRef.current.scrollLeft, null);
      }, 16);
    }
  });
}
