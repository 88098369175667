import { useState, useRef, useEffect, forwardRef, useMemo, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Modal } from 'antd';
import { composeEvent } from '@icp/utils';
import { selectContext, setFieldValue } from '@icp/form-renderer-core';
import {
  useElementDecorator,
  useFormApi,
  useFormDisabled,
  useFormReadonly,
  useIsInDesign,
} from '../../FormRenderCtx';
import { useDispatch, useSelector, useStore } from '../../store';
import {
  useClassName,
  useConditionalProperty,
  useDisplayValue,
  useVariablePattern,
} from '../../hooks';
import { ConditionalPropertyPropType } from '../../propTypes';
import { withFieldWrapper } from '../../fieldWrapper';
import { useCurrentData } from '../../currentDataCtx';
import { useTableContext } from '../TableElement/tableContextCtx';
import { getSelectedIds } from '../TableElement/utils';
import ButtonUI from './ButtonUI';
import OpenDialog from './OpenDialog';
import executeAction from './executeAction';

const ButtonElement = forwardRef(function ButtonElement(props, ref) {
  const {
    children,
    keyPath,
    id,
    valueField,
    className: classNameProp,
    disabled: disabledProp,
    style,
    componentProps = {},
  } = props;

  const {
    type,
    size,
    content: contentProp,
    style: buttonStyle,
    action = {},
    onClick,
    iconName,
    icon,
    clickUseCapture = false,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();
  const formContext = useSelector(selectContext);
  const navigate = useNavigate();
  const formApi = useFormApi();
  const currentData = useCurrentData();
  const tableApi = useTableContext();
  const [modal, contextHolder] = Modal.useModal();

  const context = useMemo(() => {
    return {
      ...formContext,
      // 暂时做个语法糖给 table toolbar 的 button request action 可以发送选中行的数据
      tableContext: {
        get selectedIds() {
          if (!tableApi?.gridApi) {
            return [];
          }
          // 如果 ag-grid 是 serverSide 并且没开启分页的时候，调用 getSelectedRows 拿不到任何的数据，
          // Ag-Table 的全选功能也是我们自定义处理的，所以这里这个函数遍历已有数据拿到选中的 id 目前没问题。
          // (除非有人需要 serverSide 的选中所有功能，并且此时 table 没有加载完所有数据，或者数据太多超过了 ag-grid 的 cache block，json 配置暂时不考虑这种情况)
          return getSelectedIds(tableApi.gridApi);
        },
        get selectedRows() {
          if (!tableApi?.gridApi) {
            return [];
          }
          return tableApi.gridApi.getSelectedRows();
        },
      },
    };
  }, [formContext, tableApi]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openCurrentAction, setOpenCurrentAction] = useState(null);

  const isUnMount = useRef(false);
  const nodeRef = useRef(null);

  const value = useDisplayValue(id, valueField);
  const content = useVariablePattern(children || contentProp);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const formDisabled = useFormDisabled();
  const formReadonly = useFormReadonly();
  const buttonDisabled = useConditionalProperty(disabledProp);

  const buttonApi = useMemo(() => {
    return {
      get node() {
        return nodeRef.current;
      },
      setLoading: setButtonLoading,
    };
  }, []);

  useImperativeHandle(ref, () => buttonApi, [buttonApi]);

  useEffect(() => {
    return () => {
      isUnMount.current = true;
    };
  }, []);

  const isDataImpactButton = action.type === 'submit' || action.type === 'save';

  // submit 和 save 一般都是提交表单数据，当表单 readonly 的时候不显示按钮，其余类型的按钮自行控制
  if (formReadonly && isDataImpactButton) {
    return null;
  }

  // 同理，当表单 disabled 的时候，影响的也只是 submit 和 save 的按钮
  const disabled = (formDisabled && isDataImpactButton) || buttonDisabled;

  const execAction = (currentAction, response) => {
    return executeAction(currentAction, response, {
      store,
      context,
      tableApi,
      currentData,
      params,
      formApi,
      buttonApi,
      modal,
      navigate,
      onOpenDialog: setOpenCurrentAction,
      onSetFieldValue: (args) => dispatch(setFieldValue(args)),
      isUnMount,
    });
  };

  const handleClick = composeEvent(
    (event) => {
      event.stopPropagation();
      // 阻止父元素有 LinkWrapper 的点击事件
      event.preventDefault();
      execAction(action);
    },
    (event) => onClick?.(event, currentData),
  );

  const handleOpenActionSuccess = (res) => {
    const { successAction } = openCurrentAction;
    if (successAction?.type !== 'open') {
      setOpenCurrentAction(null);
    }
    execAction(successAction, res);
  };

  const handleDialogClose = (event) => {
    const backup = openCurrentAction;
    setOpenCurrentAction(null);
    if (event?.state === 'success') {
      execAction(backup.successAction, event?.response);
    }
  };

  const clickProp = clickUseCapture ? 'onClickCapture' : 'onClick';

  return (
    <>
      <ElementDecorator keyPath={keyPath} id={id}>
        <ButtonUI
          {...otherComponentProps}
          className={clsx('form-element button-element', className, classNameComp)}
          style={{ ...buttonStyle, ...style }}
          type={type}
          size={size}
          icon={icon || iconName}
          disabled={disabled}
          loading={buttonLoading ? true : undefined}
          {...{ [clickProp]: isInDesign ? null : handleClick }}
          ref={nodeRef}
        >
          {value || content}
        </ButtonUI>
      </ElementDecorator>
      {openCurrentAction ? (
        <OpenDialog
          action={openCurrentAction}
          onSuccess={handleOpenActionSuccess}
          onClose={handleDialogClose}
        />
      ) : null}
      {contextHolder}
    </>
  );
});

ButtonElement.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 按钮外观类型
     * @default 'default'
     */
    type: PropTypes.oneOf(['default', 'primary', 'ghost', 'dashed', 'link', 'text']),
    /**
     * 按钮尺寸
     * @default 'middle'
     */
    size: PropTypes.oneOf(['small', 'middle', 'large']),
    /**
     * 按钮文字内容
     */
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * @deprecated, use icon instead
     */
    iconName: PropTypes.string,
    /**
     * 按钮 icon 的名字
     */
    icon: PropTypes.string,
    /**
     * 按钮动作
     */
    action: PropTypes.shape({
      /**
       * 动作类型
       */
      type: PropTypes.oneOf([
        'submit',
        'save',
        'cancel',
        'refresh',
        'refreshPage',
        'refreshTable',
        'refreshData',
        'open', // @deprecated
        'dialog',
        'request',
        'setFormData',
        'download',
        'upload',
        'link',
        'globalMethod',
        'formMethod',
        'logout',
        'confirm',
        'updateTableRow',
      ]),
      /**
       * 当 type 是 `request` 的时候，请求的方法
       */
      method: PropTypes.oneOf(['get', 'post', 'put', 'delete']),
      /**
       * 当 type 是 `request` 和 `download` 的时候，请求的地址
       */
      url: ConditionalPropertyPropType(PropTypes.string),
      /**
       * 当 type 是 `link' 的时候，链接的地址
       */
      href: ConditionalPropertyPropType(PropTypes.string),
      hrefSelector: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          matched: ConditionalPropertyPropType(PropTypes.bool),
        }),
      ),
      /**
       * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
       */
      hrefIsSiteBased: PropTypes.bool,
      /**
       * 是否直接链接不通过 react router 的 base path
       */
      suppressBasePath: PropTypes.bool,
      /**
       * 是否自动继承当前页面的 `include_deleted` 属性，此属性一般在当前页面 url 的 search params 里
       */
      suppressInheritIncludeDeleted: PropTypes.bool,
      /**
       * 当 type 是 `link' 的时候，html a 标签的 target 属性
       */
      target: PropTypes.string,
      /**
       * 当 type 是 `link' 的时候，react-router Link 的 replace 属性
       */
      replace: PropTypes.bool,
      /**
       * 当 type 是 `request` 的时候，发送给服务器的数据
       * @default formData
       */
      requestData: PropTypes.shape({}),
      /**
       * 当 type 是 `upload` 的时候，上传接受的文件类型
       */
      accept: PropTypes.string,
      /**
       * 当 type 是 `open` 的时候，打开的 form 的属性，兼容所有 `FormRenderer` 的属性
       */
      openFormProps: PropTypes.shape({
        retrieveUrl: PropTypes.string,
        createUrl: PropTypes.string,
        updateUrl: PropTypes.string,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        // all other PageRenderer props is valid
      }),
      /**
       * 当 type 是 `open` 的时候，打开的 Dialog 的属性
       */
      openDialogProps: PropTypes.shape({
        /**
         * 是否隐藏 Dialog 的 footer
         */
        hideFooter: PropTypes.bool,
        /**
         * Dialog 是否页面居中
         */
        centered: PropTypes.bool,
        /**
         * Dialog 的标题
         */
        title: PropTypes.string,
        /**
         * 默认当 openDialogProps.title 不存在的时候 dialog 会使用打开弹窗的页面/表单的 title 作为 dialog 的 title，
         * 设置 true 禁止此行为
         */
        disableUseOpenContentTitle: PropTypes.bool,
        /**
         * Dialog 预定义的宽度
         */
        size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'fullscreen']),
        /**
         * Dialog 的自定义宽度
         */
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /**
         * Dialog footer 的 "确定" 按钮的文字
         */
        okText: PropTypes.string,
        /**
         * Dialog footer 的 "取消" 按钮的文字
         */
        cancelText: PropTypes.string,
        /**
         * Dialog footer 的 "确定" 按钮的 props
         */
        okProps: PropTypes.shape({
          /**
           * @deprecated, use icon instead
           */
          iconName: PropTypes.string,
          /**
           * 按钮 icon 的名字
           */
          icon: PropTypes.string,
        }),
        /**
         * Dialog footer 的 "取消" 按钮的 props
         */
        cancelProps: PropTypes.shape({
          /**
           * @deprecated, use icon instead
           */
          iconName: PropTypes.string,
          /**
           * 按钮 icon 的名字
           */
          icon: PropTypes.string,
        }),
      }),
      /**
       * 当 type 是 `submit`, `save`, `request`, `download`, `upload`,  的时候，请求成功的提示信息
       */
      msg: PropTypes.string,
      /**
       * 当 type 是 `upload` 的时候，正在上传的提示信息
       */
      uploadingMsg: PropTypes.string,
      /**
       * 当 type 是 `upload` 的时候，上传给服务器的文件在请求中的字段名
       */
      uploadFileKey: PropTypes.string,
      /**
       * 按钮的事件成功过后执行的另一个事件，可以递归使用
       */
      successAction: PropTypes.shape({}),
      /**
       * By default, link in successAction will has 2s delay
       */
      suppressSuccessLinkDelay: PropTypes.bool,
      /**
       *
       */
      successLinkDelayTime: PropTypes.number,
      /**
       * 当 type 是 `globalMethod` 的时候，调用的 window 对象上的方法名
       */
      globalMethod: PropTypes.string,
      /**
       * @deprecated
       * 当 type 是 `open` 的时候，禁止将 `currentData` 作为 `defaultData` 传给弹框的 FormRenderer
       */
      suppressCurrentDataAsDefaultData: PropTypes.bool,
      /**
       * 当 type 是 `download` 的时候，下载的文件名
       */
      filename: PropTypes.string,
      /**
       * 当 type 是 `confirm` 的时候，确认框的标题
       */
      title: PropTypes.string,
      /**
       * 当 type 是 `confirm` 的时候，确认框的内容
       */
      content: PropTypes.string,
      /**
       * 当 type 是 `updateTableRow` 的时候，更新的操作类型
       */
      updateType: PropTypes.oneOf(['add', 'update', 'remove']),
      /**
       * 当 type 是 `updateTableRow` 的时候，更新的数据
       */
      rowData: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }),
    /**
     * 是否在捕获阶段触发点击事件
     */
    clickUseCapture: PropTypes.bool,
    /**
     * 当按钮点击的时候调用此函数
     * @param {object} event: dom event
     */
    onClick: PropTypes.func,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ButtonElement.displayName = 'Button';

export default withFieldWrapper(ButtonElement);
