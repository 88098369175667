import { findParentNode, toDateValue } from '@icp/utils';
import { getTFunc } from '@icp/i18n';
import { isEqual, merge } from 'lodash-es';
import { DEFAULT_AUTO_GROUP_COL_DEF } from '@icp/components-core';
import { loadSetting } from './settingHandler';
import { filterInvalidFilterModel } from '../Toolbar';
import { toApiFilterModel } from '../Toolbar/FilterPanel/utils';

const NUMBER_COLUMN = 'NUMBER_COLUMN';
const DATE_COLUMN = 'DATE_COLUMN';

export function loadTableInitState(settingKey, defaultValues) {
  const {
    tableSize = defaultValues.tableSize,
    columnState,
    filterModel: filterModelSaved,
    paginationPageSize = defaultValues.paginationPageSize,
  } = loadSetting(settingKey);

  let filterModel;
  if (filterModelSaved) {
    if (Array.isArray(filterModelSaved)) {
      filterModel = filterModelSaved;
    } else if (typeof filterModelSaved === 'object') {
      // 早期 localStorage save 的 filterModel 是 ag-grid 原始的 filterModel，是对象的形式。
      // 现在 save 的是数组形式，做一下 legacy support。
      filterModel = gridFilterModelToApi(filterModelSaved);
    }
  } else {
    filterModel = defaultValues.filterModel;
  }

  // 保证数组，后续代码不再做非法判断
  if (!Array.isArray(filterModel)) {
    filterModel = [];
  }

  filterModel.forEach((item) => {
    if (item.filterType === 'set' && !(Array.isArray(item.values) && item.values.length)) {
      // 移除老的葬数据空数组改为 undefined 表示不应用此 filter，
      // 否则从 ui 上看 Toolbar set 类型的过滤条件一个都没选，但是 table 没显示数据
      delete item.values;
    }

    // AgTable 组件自己保存在 localstorage 里的 values 是 [{ value, label}] 的形式，因为 Toolbar 支持 set
    // values 的懒加载获取数据，要显示已经存下来的 filter 必须要存一个 label。所以 Toolbar 里要求是 [{ value, label}]，
    // 这里做一下转化支持存下里的旧数据.
    if (item.filterType === 'set' && item.values) {
      item.values = item.values.map((v) => {
        if (typeof v === 'object') {
          return v;
        }
        return { value: v };
      });
    }
  });

  return {
    tableSize,
    columnState,
    filterModel,
    paginationPageSize,
  };
}

/*
export function toAgGridInitState({ columnState, filterModel }) {
  return {
    filter: {
      filterModel: apiFilterModelToGrid(filterInvalidFilterModel(filterModel))
    }
  }
  /!* return {
    columnOrder: {
      orderedColIds: []
    },
    columnPinning: {
      leftColIds: undefined,
      rightColIds: undefined,
    },
    columnSizing: undefined,
    columnVisibility: undefined,
    pagination: undefined,
    sort: undefined
  } *!/
} */

function mergeWithSavedState(columnDefs, columnState) {
  if (!Array.isArray(columnState)) {
    return columnDefs;
  }

  const merged = [];

  // 保存在 columnState 里的列
  for (const colState of columnState) {
    const colDef = columnDefs.find((item) => item.colId === colState.colId);
    if (colDef) {
      merged.push({
        ...colDef,
        ...colState,
        // 防止 columnState 里 ag-grid 自动生成的 index colId 或者 field 作为 colId 的覆盖
        colId: colDef.colId,
        // enableValue true 表示用于可以在页面上修改 aggFunc，此时以用户存下来的为主；
        // false 表示用户不可修改，以 colDef 为主，因为配置员可以更新 colDef.aggFunc
        aggFunc: colDef.enableValue ? colState.aggFunc : colDef.aggFunc,
        rowGroup: colDef.enableRowGroup ? colDef.rowGroup : colDef.rowGroup,
      });
    }
  }

  // 多余的不存在于保存的 columnState 里的列
  for (let index = 0; index < columnDefs.length; index++) {
    const colDef = columnDefs[index];
    const colInColumnState = columnState.find((colState) => colDef.colId === colState.colId);
    if (!colInColumnState) {
      // 新的列不放在最后，而是挨着原始列的前一列放，这比较符合直觉。如果直接放在最后的话，当用户并没有调整过列的顺序就会显得很奇怪。
      const prevIndex = merged.findIndex((item) => item.colId === columnDefs[index - 1]?.colId);
      const toInsertIndex = prevIndex + 1;
      if (colDef.children) {
        merged.splice(toInsertIndex, 0, {
          ...colDef,
          children: mergeWithSavedState(colDef.children, columnState),
        });
      } else {
        merged.splice(toInsertIndex, 0, colDef);
      }
    }
  }

  return merged;
}

function mergeAutoGroupColumnDefState(autoGroupColumnDef, columnState) {
  if (
    !autoGroupColumnDef ||
    typeof autoGroupColumnDef !== 'object' ||
    !Array.isArray(columnState)
  ) {
    return autoGroupColumnDef;
  }

  const savedState = columnState.find((colState) => colState.colId === 'ag-Grid-AutoColumn');

  if (!savedState) {
    return autoGroupColumnDef;
  }

  return {
    ...autoGroupColumnDef,
    ...savedState,
  };
}

export function mergeWithDefaultColDef(columnDefs, defaultColDef, columnTypes) {
  return columnDefs.map((colDef) => {
    const typeDef = columnTypes?.[colDef.type] || {};
    // colDef 覆盖 typeDef 覆盖 defaultColDef
    return merge({}, defaultColDef, typeDef, colDef);
  });
}

export function formatColumnDefs({
  autoGroupColumnDefProp,
  columnDefsProp,
  defaultColDef,
  columnTypes,
  settingKey,
  columnState: csSaved,
  treeData,
}) {
  const t = getTFunc('icp-components');
  const columnState =
    Array.isArray(csSaved) &&
    csSaved.map((cs) => {
      const { pivot, pivotIndex, rowGroup, rowGroupIndex, ...other } = cs;
      if (treeData) {
        // ag-grid treeData with pivot and rowGroup property will throw warning
        return other;
      }
      return cs;
    });

  let columnDefs = columnDefsProp;

  if (!Array.isArray(columnDefsProp)) {
    columnDefs = [];
  }

  // 当 colDef 里只有 field 没有 colId 的时候，ag-grid 会把 field 作为 colId 保存到 columnState 里，
  // 当 colDef 了 colId 和 field 都没有的时候，ag-grid 会用递增 index 的方式来作为 colId 保存在 columnState 里，
  // 这里补一个，否则在 mergeWithSavedState 就不认识了
  let autoIndex = 0;
  columnDefs = columnDefs.map((colDef) => {
    if (!colDef.colId && !colDef.field && settingKey) {
      console.warn(t('table.warn.no-colId'), colDef);
    }

    return {
      headerTooltip: colDef.headerName,
      colId: colDef.colId ?? colDef.field ?? `${autoIndex++}`,
      ...colDef,
    };
  });

  columnDefs = mergeWithDefaultColDef(columnDefs, defaultColDef, columnTypes);
  columnDefs = mergeWithSavedState(columnDefs, columnState);

  const autoGroupColumnDef = mergeAutoGroupColumnDefState(
    {
      ...DEFAULT_AUTO_GROUP_COL_DEF,
      ...autoGroupColumnDefProp,
      headerTooltip: autoGroupColumnDefProp?.headerName,
    },
    columnState,
  );

  return { autoGroupColumnDef, columnDefs };
}

function formatDateFilter(params, filterModel) {
  if (!Array.isArray(filterModel)) {
    return;
  }

  for (const item of filterModel) {
    if (item.filterType === 'date') {
      const column = params.api.getColumn(item.colId);

      if (!column) {
        continue;
      }

      const { filterParams } = column.colDef;
      const { showTime = false, noTimeZone = false } = filterParams || {};

      if (item.dateFrom) {
        item.dateFrom = toDateValue(item.dateFrom, { showTime, noTimeZone });
      }
      if (item.dateTo) {
        item.dateTo = toDateValue(item.dateTo, { showTime, noTimeZone });
      }
    }
  }
}

export function gridFilterModelToApi(gridFilterModel) {
  return Object.keys(gridFilterModel).map((colId) => {
    return {
      colId,
      ...gridFilterModel[colId],
    };
  });
}

export function apiFilterModelToGrid(apiFilterModel) {
  if (!apiFilterModel) {
    return null;
  }

  const filterModel = {};

  for (const item of apiFilterModel) {
    const { colId, ...other } = item;
    // AgTable 组件自己保存在 localstorage 里的 values 是 [{ value, label}] 的形式，ag-grid 需要的是字符串数组
    if (item.filterType === 'set') {
      if (Array.isArray(other.values) && other.values.length) {
        other.values = other.values.map((v) => {
          return typeof v === 'object' && v ? v.value : v;
        });
      } else {
        // 非法 values 不传给 ag-grid
        continue;
      }
    }
    filterModel[colId] = other;
  }

  return filterModel;
}

export function transformRequestToApi(params, filterModelState, searchText) {
  const { api: gridApi, request } = params;
  const columnDefs = gridApi.getAllGridColumns().map((col) => col.getColDef());

  // 支持一下不通过 Toolbar 通过其余方式从 ag-grid getRows 传出来的 filterModel
  // 同样 key 的以 filterModelState 优先
  const filterModel = [
    ...gridFilterModelToApi(request.filterModel),
    ...toApiFilterModel(filterModelState, columnDefs),
  ];

  formatDateFilter(params, filterModel);

  // TODO, 在 TableElement 里做这个事
  // add sortType: number for type: NUMBER_COLUMN,
  // add sortType: date for type: DATE_COLUMN
  const sortModel = request.sortModel.map((item) => {
    const { colDef = {} } = params.api.getColumn(item.colId) || {};

    const isNumberColumn = colDef.type === NUMBER_COLUMN;
    const isDateColumn = colDef.type === DATE_COLUMN;
    const sortType = (isNumberColumn && 'number') || (isDateColumn && 'date') || undefined;

    return { ...item, sortType };
  });

  const rowGroupCols = request.rowGroupCols.map((item) => {
    const { id, ...other } = item;
    return { colId: id, ...other };
  });

  const valueCols = request.valueCols.map((item) => {
    const { id, ...other } = item;
    return { colId: id, ...other };
  });

  const removeEmpty = (obj) => {
    const newObj = { ...obj };

    for (const [k, v] of Object.entries(newObj)) {
      if (v === undefined || v === '' || v === false || (Array.isArray(v) && v.length === 0)) {
        delete newObj[k];
      }
    }

    return newObj;
  };

  return removeEmpty({
    ...request,
    filterModel,
    sortModel,
    searchText,
    rowGroupCols,
    valueCols,
  });
}

export function getSelectableColumns(gridApi, searchText) {
  return gridApi.getAllGridColumns().filter((column) => {
    const { colDef } = column;
    return (
      !(colDef.colId === undefined || colDef.colId === null) &&
      colDef.colId !== 'ag-Grid-ControlsColumn' &&
      (!searchText || colDef.headerName?.toLowerCase().includes(searchText))
    );
  });
}

export function getHasColumnCanFilter({ columnDefs, defaultColDef, columnTypes }) {
  return !!columnDefs.find((colDef) => {
    if (colDef.filter) return true;
    if (colDef.filter === undefined) {
      return defaultColDef?.filter || columnTypes?.[colDef.type]?.filter;
    }
    return false;
  });
}

export function isStateEqual(stateA, stateB, checkEmptyFilter = false) {
  const removeFlex = (columnState) => {
    if (!Array.isArray(columnState)) {
      return columnState;
    }

    return columnState.map((cs) => {
      return {
        ...cs,
        // columnState 里有 flex 的列表示宽度是自动缩放的，不进行比较 width
        width: cs.flex !== undefined && cs.flex !== null ? undefined : cs.width,
      };
    });
  };

  const removeEmpty = (state) => {
    const copy = JSON.parse(JSON.stringify(state));

    const remove = (obj) => {
      for (const [k, v] of Object.entries(obj)) {
        if (
          // undefined null 空字符串 空数组 空对象都当做不存在
          v === undefined ||
          v === null ||
          v === '' ||
          (Array.isArray(v) && v.length === 0) ||
          (typeof v === 'object' && v && !Object.keys(v).length)
        ) {
          delete obj[k];
        } else if (typeof v === 'object') {
          // v 的所有属性都被删了，v 本身也无意义
          const nestedEmpty = remove(v);
          if (nestedEmpty) {
            delete obj[k];
          }
        }
      }

      return !Object.keys(obj).length;
    };

    remove(copy);

    return copy;
  };

  const objA = removeEmpty({
    ...stateA,
    columnState: removeFlex(stateA.columnState),
    filterModel: checkEmptyFilter
      ? filterInvalidFilterModel(stateA.filterModel)
      : stateA.filterModel,
  });
  const objB = removeEmpty({
    ...stateB,
    columnState: removeFlex(stateB.columnState),
    filterModel: checkEmptyFilter
      ? filterInvalidFilterModel(stateB.filterModel)
      : stateB.filterModel,
  });

  return isEqual(objA, objB);
}

export function getTableCurrentPageRowNodes(gridApi) {
  const currentPage = gridApi.paginationGetCurrentPage();
  const pageSize = gridApi.paginationGetPageSize();

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;

  const nodes = [];
  gridApi.forEachNode((node) => {
    nodes.push(node);
  });

  return nodes.slice(startIndex, endIndex);
}

/* function isParentHasClass(target, classNames) {
  let parent = target.parentNode;

  const has = () => {
    if (parent === document.body || parent === document) {
      return false;
    }

    const { classList } = parent;
    for (const name of classNames) {
      if (classList.contains(name)) {
        return true;
      }
    }

    parent = parent.parentNode;

    return has();
  };

  return has();
} */

function findRootAncestor(target) {
  return findParentNode(target, (el) => {
    if (el === document.body || el === document) {
      return true;
    }
    for (const name of [
      'ag-popup',
      'ant-picker-dropdown',
      'ant-select-dropdown',
      'ant-modal',
      'MuiModal-root',
      'MuiPickersPopper-root',
      'icp-overlay',
    ]) {
      if (el.classList.contains(name)) {
        return true;
      }
    }
    return false;
  });
}

export function isClickOutDataArea(target, container) {
  const dataArea = container.querySelector('.ag-body-viewport');
  // 找到最近的祖先 popup 或者 document.body
  const targetRootAncestor = findRootAncestor(target);
  const tableRootAncestor = findRootAncestor(container);

  // 实现点击 table 数据外的空白处停止编辑，只有当 event.target 和 table 处于同一个 document.body 下
  // 或者同一个 popup 里才停止编辑，解决 3 种情况
  // 1. 点击 cellEditor 的 popup，或者
  // 2. 点击 acl cellEditor 弹出的 dialog 里的 table 的空白 ag-body-viewport 位置， 或者
  // 3. cell 里的 button 打开的 dialog 里包含的子嵌套 table 在编辑
  return (
    targetRootAncestor === tableRootAncestor &&
    (target.classList.contains('ag-center-cols-viewport') ||
      target.classList.contains('ag-body-viewport') ||
      !dataArea.contains(target))
  );
}

export function isFilterModelEqual(filterModelApi, filterModelGrid) {
  // 过滤非法 filter 的值，比如从 ui 上删除 text filterModelApi 里可能有 filter 是 ''，aggrid 的 api.getFilterType() 会自动过滤掉这些值
  return isEqual(apiFilterModelToGrid(filterInvalidFilterModel(filterModelApi)), filterModelGrid);
}
