// import { isAnyOf } from '@reduxjs/toolkit';
import { EvalWorker } from '@icp/utils';
import {
  setFieldTouched,
  internalSetFieldValue,
  processValueExpression,
  validateField,
  selectValues,
  validateForm,
  setFieldValue,
  fetchFormData,
  handleSubmitSuccess,
} from './formDataSlice';

export default [
  // 字段脏时触发校验
  {
    type: setFieldTouched.toString(),
    effect: async (action, { dispatch }) => {
      const { keyPath, touched } = action.payload;
      if (touched) {
        await dispatch(validateField(keyPath));
      }
    },
  },
  // 字段值变化时
  // 触发表达式计算
  // 触发onFieldValueChange回调
  {
    type: internalSetFieldValue.toString(),
    effect: async (action, { dispatch, getState, extra }) => {
      const { keyPath, source } = action.payload;
      if (!keyPath) return;

      await dispatch(processValueExpression({ keyPath, source }));

      const { onFieldValueChange } = extra;
      const { eventService } = extra.formApi;

      // legacy support for string type onFieldValueChange，
      // 以前 json 模式下表单不能写 js，所以最早加了个 string 的 onFieldValueChange 来坚挺数据操作。现在可以
      // 统一在 form js 里处理了，这种用法不推荐了。
      if (typeof onFieldValueChange === 'string') {
        if (process.env.NODE_ENV === 'development') {
          console.warn('onFieldValueChange as string is deprecated, use function instead');
        }
        const evaluated = await EvalWorker.shared().execEval(action.payload, onFieldValueChange, {
          params: {
            context: getState().context,
            formData: selectValues(getState()),
          },
        });
        if (evaluated && typeof evaluated === 'object') {
          Object.entries(evaluated).forEach(([k, v]) => {
            dispatch(setFieldValue({ keyPath: k, value: v, source: [...(source || []), keyPath] }));
          });
        }
      }

      eventService.dispatch({ type: 'fieldValueChange', payload: action.payload });
      eventService.dispatch({
        type: 'change',
        // copy 一份，防止通过 js 代码再设置给 Table 之类的组件，会导致 Table 无法再 mutable 的修改数据
        payload: JSON.parse(JSON.stringify(selectValues(getState()))),
      });
    },
  },
  // 验证表单结束后触发onValidationFail回调
  {
    type: validateForm.fulfilled.toString(),
    effect: async (action, { extra }) => {
      const errors = action.payload;
      if (!errors) return;

      extra.formApi.eventService.dispatch({ type: 'validationFail', payload: errors });
    },
  },
  // 如果是上锁的数据，发心跳包延锁时间，直到心跳包报错或表单提交/取消
  {
    type: fetchFormData.fulfilled.toString(),
    effect: async (action, { extra }) => {
      const formEntityData = action.payload;
      // eslint-disable-next-line no-underscore-dangle
      if (formEntityData._lock) {
        extra.heartbeat.register({
          pbcToken: formEntityData.pbcToken,
          formEntityToken: formEntityData.formEntityToken,
          formEntityDataId: formEntityData.id,
        });
      }
    },
  },
  // 提交成功停止心跳包
  {
    type: handleSubmitSuccess.toString(),
    effect: async (action, { extra }) => {
      const formEntityData = action.payload.newData;
      extra.heartbeat.unregister({
        pbcToken: formEntityData?.pbcToken,
        formEntityToken: formEntityData?.formEntityToken,
        formEntityDataId: formEntityData?.id,
      });
    },
  },
  // 失败的action打印日志
  {
    predicate: (action) => {
      return action.type?.endsWith(`/rejected`);
    },
    effect: (action) => {
      if (action.error?.stack) {
        console.error(action.error.stack);
      } else {
        console.error(action.error);
      }
    },
  },
];
