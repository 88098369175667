import { getTFunc } from '@icp/i18n';
import { isInputField, isLayout } from '@icp/form-schema';
import { v4 as uuidv4 } from 'uuid';
import { parseJSON } from '@icp/utils';
import { FIELD_VALUE_MAX_LENGTH } from '../../constant';
import * as chartOptionsHelper from '../chartOptions';
import {
  dataFieldToSchemaField,
  findSchemaFieldInEntityByToken,
  getUniqueId,
} from './formEntityHelper';

export function makeNewFormSchema(defaultButton) {
  return {
    form: {
      defaultSubmitButton: defaultButton,
      defaultCancelButton: defaultButton,
      labelLayout: 'vertical',
    },
    fields: [],
  };
}

export function makeNewFormEntity(pbcId, isInnerEntity, initialName, defaultButton) {
  const t = getTFunc('icp-form-designer');

  return {
    pbcId,
    name: isInnerEntity ? uuidv4() : '',
    // Fields is union of fields in all layout
    isInnerEntity: Boolean(isInnerEntity),
    fields: [],
    layouts: [
      {
        defaultLayout: true,
        name: initialName ?? t('default'),
        token: 'default-layout',
        schema: makeNewFormSchema(defaultButton),
      },
    ],
  };
}

export function makeNewTableColumn() {
  const t = getTFunc('icp-form-designer');
  return {
    // 没有 id canvas 可视化选择会很难识别到底是哪一列，为了发方便操作加一个随机 id
    colId: `empty-column-${Date.now()}`,
    field: `empty-column-${Date.now()}`,
    headerName: t('table.select-data-source'),
  };
}

export function makeNewStep(nextIndex) {
  const t = getTFunc('icp-form-designer');
  return { title: t('steps.step.new-title', { stepNumber: nextIndex + 1 }) };
}

export function makeNewCollapseStep(nextIndex) {
  const t = getTFunc('icp-form-designer');
  return { key: getUniqueId(), label: t('collapse.panel.new-title', { number: nextIndex + 1 }) };
}

export function makeNewTab(nextIndex) {
  const t = getTFunc('icp-form-designer');
  return { key: getUniqueId(), label: t('tabs.tab.new-title', { number: nextIndex + 1 }) };
}

export function makeNewField(name, options) {
  const t = getTFunc('icp-form-designer');
  const fieldInfo = { component: name };
  const { domain, module /* , pbcToken, formEntityToken */ } = options || {};

  if (!name) {
    return null;
  }

  if (isInputField(fieldInfo)) {
    fieldInfo.id = '';
    fieldInfo.title = t(`component.${name}`);

    if (domain) {
      fieldInfo.domain = domain;
    }
    if (module) {
      fieldInfo.module = module;
    }
  }

  if (name === 'Input') {
    fieldInfo.validation = {
      maxLength: FIELD_VALUE_MAX_LENGTH,
    };
  }

  if (name === 'Cascader' || name === 'Select' || name === 'TreeSelect' || name === 'ACL') {
    fieldInfo.componentProps = {
      stringEqual: true,
    };
  }

  if (name === 'Radio' || name === 'Checkbox') {
    fieldInfo.componentProps = {
      options: [],
      direction: 'horizontal',
    };
  }

  if (name === 'Upload') {
    fieldInfo.componentProps = {
      listType: 'text',
    };
  }

  if (name === 'Button') {
    fieldInfo.componentProps = {
      content: 'Button',
    };
  }

  if (name === 'TodoList') {
    fieldInfo.componentProps = {
      title: 'My to-do list',
    };
  }

  if (name === 'Text') {
    fieldInfo.componentProps = {
      content: 'Text',
    };
  }

  if (name === 'Icon') {
    fieldInfo.componentProps = {
      name: 'material:emoji-food-beverage',
      size: 24,
    };
  }

  if (name === 'Table') {
    fieldInfo.id = `${getUniqueId()}-table`;
    fieldInfo.componentProps = {
      // add a default column placeholder to make sure SelectionBox can select at least on column.
      columnDefs: [makeNewTableColumn()],
    };
  }

  if (name === 'Gantt') {
    fieldInfo.id = `${getUniqueId()}-gantt`;
    fieldInfo.componentProps = {
      // add a default column placeholder to make sure SelectionBox can select at least on column.
      columnDefs: [makeNewTableColumn()],
    };
  }

  if (name === 'HStack') {
    fieldInfo.component = 'Stack';
    fieldInfo.componentProps = {
      flexDirection: 'row',
    };
  }

  if (name === 'VStack') {
    fieldInfo.component = 'Stack';
    fieldInfo.componentProps = {
      flexDirection: 'column',
    };
  }

  if (name === 'Steps') {
    fieldInfo.componentProps = {
      items: [
        {
          title: t('steps.step.new-title', { stepNumber: 1 }),
        },
        {
          title: t('steps.step.new-title', { stepNumber: 2 }),
        },
        {
          title: t('steps.step.new-title', { stepNumber: 3 }),
        },
      ],
    };
  }

  if (name === 'Collapse') {
    const key = getUniqueId();
    fieldInfo.componentProps = {
      defaultActiveKey: key,
      itemSplit: true,
      items: [
        {
          key,
          label: t('collapse.panel.new-title', { number: 1 }),
        },
      ],
    };
  }

  if (name === 'Tabs') {
    fieldInfo.componentProps = {
      items: [
        {
          key: getUniqueId(),
          label: t('tabs.tab.new-title', { number: 1 }),
        },
        {
          key: getUniqueId(),
          label: t('tabs.tab.new-title', { number: 2 }),
        },
      ],
    };
  }

  if (name.endsWith('Chart')) {
    fieldInfo.component = 'EChart';
    fieldInfo.componentProps = {
      style: {
        height: 300,
      },
      echartOption: {},
    };

    switch (name) {
      case 'LineChart':
        fieldInfo.componentProps.category = 'axis';
        fieldInfo.componentProps.series = chartOptionsHelper.makeLineSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makeLineOption();
        break;
      case 'BarChart':
        fieldInfo.componentProps.category = 'axis';
        fieldInfo.componentProps.series = chartOptionsHelper.makeBarSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makeBarOption();
        break;
      case 'AreaChart':
        fieldInfo.componentProps.category = 'axis';
        fieldInfo.componentProps.series = chartOptionsHelper.makeAreaSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makeAreaOption();
        break;
      case 'PieChart':
        fieldInfo.componentProps.category = 'pie';
        fieldInfo.componentProps.series = chartOptionsHelper.makePieSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makePieOption();
        break;
      case 'DoughnutChart':
        fieldInfo.componentProps.category = 'pie';
        fieldInfo.componentProps.series = chartOptionsHelper.makeDonutSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makeDonutOption();
        break;
      case 'ScatterChart':
        fieldInfo.componentProps.category = 'axis';
        fieldInfo.componentProps.series = chartOptionsHelper.makeScatterSeries();
        fieldInfo.componentProps.echartOption = chartOptionsHelper.makeScatterOption();
        break;
      default:
        break;
    }
  }

  if (name === 'PageHeader') {
    fieldInfo.fields = [
      {
        component: 'Button',
        componentProps: {
          content: t('component.Button'),
          type: 'primary',
          icon: 'material:add-rounded',
        },
      },
    ];
  }

  if (name === 'Page') {
    fieldInfo.componentProps = {
      empty: true,
    };
  }

  if (isLayout(fieldInfo)) {
    fieldInfo.fields = [];
  }

  return fieldInfo;
}

export function makeFieldFromToken(formEntity, token) {
  if (!token) return null;

  const dataField = formEntity.fields.find((field) => field.token === token);

  if (!dataField) {
    return null;
  }

  // 从第一个 layout 里取 field，虽然多个 layout 里的 componentProps 可能会不同，但是也只能这么处理
  return findSchemaFieldInEntityByToken(formEntity, dataField.token);
}

export function makeNewFieldFromSnippet(snippet) {
  if (!snippet) return null;
  const obj = parseJSON(snippet);
  if (!obj) return null;
  return obj;
}

export function makeNewFieldFromBusinessField(bizField, currentLayoutField) {
  if (!bizField) return null;
  const obj = dataFieldToSchemaField(bizField, currentLayoutField);
  if (!obj) return null;
  obj.source = bizField.uuid;
  return obj;
}
